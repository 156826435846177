import _defaults from "lodash-es/defaults";
import { colors, Layout, useNotifications } from '@lookout/ui';
import React, { useState, useEffect, Suspense, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useResource, useResourceOnce } from '@lookout/suspense';
import { ErrorBoundary } from 'react-error-boundary';
import ActionButton from '../micro/action-button.js';
import useTrackView from '../../lib/analytics/use-track-view.js';
import { mobileResponsivePadding, mobileResponsiveMargin } from '../../lib/styles/layout-styles.js';
import { belowQuery, screenSmMax, screenXsMax } from '../../lib/styles/breakpoints.js';
import { toPlainObject } from '../../lib/utils/query-params.js';
import RegisterAppModal from '../apps/register-app-modal.js';
import NetworkErrorBoundary from '../../lib/network-error-boundary.js';
import Fallback from '../micro/fallback.js';
import { readApps } from '../apps/apps-service.js';
import I18n from '../../i18n.js';
import { readDeviceDetections, readDeviceRegistrations, readAllDetections } from './dashboard-service.js';
import DocsCollapsible from './docs-collapsible.js';
import DetectionsChart from './charts/detections-chart.js';
import RegistrationsChart from './charts/registrations-chart.js';
import DashboardThreats from './charts/dashboard-threats.js';
import DashboardFilter from './dashboard-filter.js';
import { defaultQuery } from './dashboard-utils.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const gridContainerStyle = {
  margin: 30,
  ...mobileResponsiveMargin,
  backgroundColor: colors.gray300,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr'
};
const gridItemStyle = {
  margin: 10,
  minWidth: 350,
  justifyContent: 'center',
  [belowQuery({
    max: screenSmMax
  })]: {
    gridColumnEnd: 'span 2'
  },
  [belowQuery({
    max: screenXsMax
  })]: {
    marginRight: 0,
    marginLeft: 0,
    ':last-child': {
      marginBottom: 0
    }
  }
};
const chartTitleStyle = {
  margin: '15px 20px',
  color: colors.black200,
  letterSpacing: '0.4px'
};
const tabsTheme = {
  Tabs: {
    marginBottom: 20,
    height: 80
  },
  Tab: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    margin: 0,
    border: `1px solid ${colors.gray300}`,
    borderRight: 0,
    padding: '2px 0',
    backgroundColor: colors.white,
    textAlign: 'center',
    fontSize: 20,
    lineHeight: 1,
    '&[aria-selected=true]': {
      borderBottom: `3px solid ${colors.darkGray200}`,
      paddingBottom: 0
    },
    ':first-of-type': {
      borderLeft: 0
    },
    span: {
      marginTop: 10,
      fontSize: 11
    }
  }
};
const buttonStyle = {
  marginLeft: 20,
  [belowQuery({
    max: screenSmMax
  })]: {
    marginLeft: 10
  }
};
const Dashboard = () => {
  const [searchParams] = useSearchParams();
  const query = useMemo(() => _defaults({}, toPlainObject(searchParams), defaultQuery), [searchParams]);
  const apps = useResourceOnce(readApps);
  const [deviceDetections, isDeviceDetectionsPending, startDeviceDetectionsTransition] = useResource(readDeviceDetections);
  const [deviceRegistrations, isDeviceRegistrationsPending, startDeviceRegistrationsTransition] = useResource(readDeviceRegistrations);
  const [detections, isDetectionsPending, startDetectionsTransition] = useResource(readAllDetections);
  useEffect(() => {
    startDeviceRegistrationsTransition({
      query
    });
    startDeviceDetectionsTransition({
      query
    });
    startDetectionsTransition({
      query
    });
  }, [startDeviceRegistrationsTransition, startDeviceDetectionsTransition, startDetectionsTransition, query]);
  const [isRegisterAppModalOpen, setRegisterAppModalOpen] = useState();
  const {
    notifySuccess
  } = useNotifications();
  useTrackView('dashboard');
  return _jsx("div", {
    className: "dashboard",
    css: {
      display: 'flex',
      // TODO charts: without flex 30px (20 on mobile) dashboard margin at bottom left uncovered
      flexDirection: 'column',
      minHeight: '100%',
      backgroundColor: colors.gray300
    },
    children: _jsx(ErrorBoundary, {
      fallback: _jsx(Fallback, {
        className: "dashboard-unhandled-error",
        message: I18n.t('error.generic.title')
      }),
      children: deviceDetections && deviceRegistrations && detections ? _jsxs(Suspense, {
        fallback: _jsx(Fallback, {
          className: "dashboard-charts-pending",
          spinner: true
        }),
        children: [_jsxs("div", {
          css: {
            padding: '40px 40px 20px 40px',
            ...mobileResponsivePadding,
            backgroundColor: colors.white
          },
          children: [_jsxs(Layout, {
            horizontal: true,
            css: {
              marginBottom: 20,
              alignItems: 'center',
              justifyContent: 'space-between'
            },
            children: [_jsx("h1", {
              css: {
                margin: 0,
                flex: 2,
                whiteSpace: 'nowrap'
              },
              children: I18n.t('dashboard.header.title')
            }), _jsx(ActionButton, {
              css: {
                ...buttonStyle,
                backgroundColor: colors.primary,
                color: colors.white
              },
              onClick: () => setRegisterAppModalOpen(true),
              className: "register-app-button",
              children: I18n.t('apps_page.register_button')
            })]
          }), _jsx(NetworkErrorBoundary, {
            fallback: _jsx("div", {
              className: "dashboard-filter-error",
              children: I18n.t('error.service.apps')
            }),
            children: _jsx(DashboardFilter, {
              apps: apps
            })
          }), _jsx(DocsCollapsible, {})]
        }), isRegisterAppModalOpen && _jsx(RegisterAppModal, {
          onClosed: result => {
            setRegisterAppModalOpen(false);
            if (result) notifySuccess(I18n.t('apps_page.alert.new_app_success', result));
          }
        }), _jsxs("div", {
          css: gridContainerStyle,
          children: [_jsx("div", {
            css: gridItemStyle,
            children: _jsx(NetworkErrorBoundary, {
              resetKeys: [deviceRegistrations],
              fallback: _jsx(Fallback, {
                className: "dashboard-registrations-error",
                message: I18n.t('error.service.dashboard')
              }),
              children: _jsx(RegistrationsChart, {
                titleStyle: chartTitleStyle,
                height: 380,
                tabsTheme: tabsTheme,
                deviceRegistrations: deviceRegistrations,
                isPending: isDeviceRegistrationsPending,
                dateRange: query.date_range
              })
            })
          }), _jsx("div", {
            css: {
              ...gridItemStyle,
              display: 'flex'
            },
            children: _jsx(NetworkErrorBoundary, {
              resetKeys: [deviceDetections],
              fallback: _jsx(Fallback, {
                className: "dashboard-detections-error",
                message: I18n.t('error.service.dashboard')
              }),
              children: _jsx(DetectionsChart, {
                titleStyle: chartTitleStyle,
                height: 400,
                deviceDetections: deviceDetections,
                isPending: isDeviceDetectionsPending,
                dateRange: query.date_range,
                apps: apps,
                onRegisterAppModalClick: () => setRegisterAppModalOpen(true)
              })
            })
          }), _jsx("div", {
            css: {
              ...gridItemStyle,
              gridColumnEnd: 'span 2'
            },
            children: _jsx(NetworkErrorBoundary, {
              resetKeys: [detections],
              fallback: _jsx(Fallback, {
                className: "dashboard-threats-error",
                message: I18n.t('error.service.dashboard')
              }),
              children: _jsx(DashboardThreats, {
                titleStyle: chartTitleStyle,
                tabsTheme: tabsTheme,
                detections: detections,
                isPending: isDetectionsPending,
                dateRange: query.date_range
              })
            })
          })]
        })]
      }) : _jsx(Fallback, {
        className: "dashboard-charts-pending",
        spinner: true
      })
    })
  });
};
export default Dashboard;