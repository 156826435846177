import React from 'react';
import { Container } from '../micro/container.js';
import Markdownify from '../micro/markdownify.js';
import IosMarkdown from './ios.md';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const IosDocs = () => _jsx(Container, {
  className: "ios-docs-page",
  children: _jsx(Markdownify, {
    md: IosMarkdown
  })
});
export default IosDocs;