import I18n from '../../i18n.js';
const getPageTitle = titleKey => {
  const baseTitle = I18n.t('doc_title.base');
  const messageObject = I18n.t(`doc_title.${titleKey}`, {
    missingBehavior: 'empty'
  }) && I18n.t(`doc_title.${titleKey}`);
  return messageObject ? I18n.t('doc_title.combined', {
    base: baseTitle,
    subtitle: messageObject
  }) : baseTitle;
};
export default getPageTitle;