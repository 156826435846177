import _isEmpty from "lodash-es/isEmpty";
import _lowerCase from "lodash-es/lowerCase";
import _filter from "lodash-es/filter";
import _orderBy from "lodash-es/orderBy";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { IconButton, Button, Table, OSIcon, tableStyles, typography, useLoading, TableActionCell } from '@lookout/ui';
import { MiscDelete, MiscPencil } from '@lookout/dingbats';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from '../../lib/utils/date-fns-localized.js';
import { tableLayoutStyles } from '../../lib/styles/layout-styles.js';
import classes from '../../lib/utils/classes.js';
import { DoubleHeader } from '../micro/double-header.js';
import Fallback from '../micro/fallback.js';
import I18n from '../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const sortApps = apps => _orderBy(apps, ['createdTime'], ['desc']);
const filterApps = (apps, filter) => _filter(apps, app => filter ? _lowerCase(app?.appName)?.indexOf(_lowerCase(filter)) !== -1 : apps);
const AppsListRow = _ref => {
  let {
    app,
    modals,
    setModal
  } = _ref;
  const navigate = useNavigate();
  const showGetKeyModal = useCallback((event, _ref2) => {
    let {
      applicationGuid
    } = _ref2;
    event.stopPropagation();
    setModal({
      type: modals.GET_KEY_MODAL,
      options: {
        applicationGuid
      }
    });
  }, [modals.GET_KEY_MODAL, setModal]);
  const showEditAppModal = useCallback((event, options) => {
    event.stopPropagation();
    setModal({
      type: modals.EDIT_APP_MODAL,
      options
    });
  }, [modals.EDIT_APP_MODAL, setModal]);
  const showDeleteAppModal = useCallback((event, options) => {
    event.stopPropagation();
    setModal({
      type: modals.DELETE_APP_MODAL,
      options
    });
  }, [modals.DELETE_APP_MODAL, setModal]);
  return useMemo(() => _jsxs("tr", {
    onClick: () => navigate(`/apps/${app.applicationGuid}`),
    children: [_jsx("td", {
      css: [typography.breakText, {
        minWidth: 200
      }],
      children: _jsx(DoubleHeader, {
        className: "app-name",
        top: app.appName,
        bottom: app.packageName
      })
    }), _jsx("td", {
      css: {
        minWidth: 100
      },
      children: _jsx(OSIcon, {
        className: classes('app-os', app.os),
        os: app.os
      })
    }), _jsx("td", {
      css: [typography.breakText, {
        minWidth: 200
      }],
      children: _jsx(DoubleHeader, {
        className: "app-developer",
        top: app.userName,
        bottom: app.userEmail
      })
    }), _jsx("td", {
      children: _jsx(DoubleHeader, {
        className: "app-created-time",
        top: format(app.createdTime, 'M/d/yyyy'),
        bottom: format(app.createdTime, 'p')
      })
    }), _jsxs(TableActionCell, {
      children: [_jsx(Button, {
        className: "get-key-button",
        onClick: event => showGetKeyModal(event, app),
        link: true,
        children: I18n.t('apps_list.action_buttons.key')
      }, "get-key"), _jsx(IconButton, {
        Icon: MiscPencil,
        className: "edit-app-button",
        onClick: event => showEditAppModal(event, app)
      }, "edit"), _jsx(IconButton, {
        className: "delete-app-button",
        Icon: MiscDelete,
        onClick: event => showDeleteAppModal(event, app)
      }, "delete")]
    })]
  }), [app, navigate, showGetKeyModal, showEditAppModal, showDeleteAppModal]);
};
const AppsList = _ref3 => {
  let {
    filter,
    apps,
    modals,
    setModal,
    isPending
  } = _ref3;
  const appsResult = apps.value();
  const {
    elementRef
  } = useLoading(isPending);
  const sortedApps = useMemo(() => sortApps(appsResult), [appsResult]);
  const filteredApps = useMemo(() => filterApps(sortedApps, filter), [sortedApps, filter]);
  return filteredApps?.length ? _jsx("div", {
    css: {
      overflow: 'auto'
    },
    children: _jsxs(Table, {
      className: "apps-list-table",
      css: [tableStyles.striped, tableStyles.hover, tableStyles.cursorPointer, tableStyles.loading, tableLayoutStyles.fullWidth, {
        'thead tr th': {
          whiteSpace: 'nowrap'
        }
      }],
      children: [_jsx("thead", {
        children: _jsxs("tr", {
          children: [_jsx("th", {
            children: I18n.t('apps_list.col.app_name')
          }), _jsx("th", {
            children: I18n.t('apps_list.col.os')
          }), _jsx("th", {
            children: I18n.t('app_details.developer')
          }), _jsx("th", {
            children: I18n.t('apps_list.col.created_on')
          }), _jsx("th", {})]
        })
      }), _jsx("tbody", {
        ref: elementRef,
        children: filteredApps && filteredApps.map(app => _jsx(AppsListRow, {
          app: app,
          modals: modals,
          setModal: setModal
        }, app.applicationGuid))
      })]
    })
  }) : _jsx(Fallback, {
    className: "apps-list-empty",
    message: _isEmpty(filter) ? I18n.t('apps_page.no_apps_at_all') : I18n.t('apps_page.no_apps_filter')
  });
};
export default AppsList;