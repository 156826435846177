import { ServerError } from '@lookout/request';
import requestWithToken from '../../lib/api/request-with-token.js';
import { appdefApiUrlFor } from '../../lib/api/urls.js';
import { stringifyQuery } from '../../lib/utils/query-params.js';
import { isForbiddenDateRange } from './dashboard-utils.js';
const assertQuery = query => {
  if (isForbiddenDateRange(query)) {
    throw new ServerError('date range forbidden');
  }
};
export const readDeviceDetections = async _ref => {
  let {
    query,
    ...opts
  } = _ref;
  assertQuery(query);
  return requestWithToken(appdefApiUrlFor(`/${globalThis.cache.ent.guid}/device-detections/timeseries?${stringifyQuery(query)}`), opts);
};
export const readDeviceRegistrations = async _ref2 => {
  let {
    query,
    ...opts
  } = _ref2;
  assertQuery(query);
  return requestWithToken(appdefApiUrlFor(`/${globalThis.cache.ent.guid}/device-registrations/total?${stringifyQuery(query)}`), opts);
};
export const readDetections = _ref3 => {
  let {
    category = 'ALL',
    query,
    ...opts
  } = _ref3;
  return requestWithToken(appdefApiUrlFor(`/${globalThis.cache.ent.guid}/detections/total?${stringifyQuery({
    category,
    ...query
  })}`), opts);
};
export const readAllDetections = async _ref4 => {
  let {
    query,
    ...opts
  } = _ref4;
  assertQuery(query);
  return Promise.all(['APP_THREATS', 'DEVICE', 'NETWORK', 'ALL'].map(category => readDetections({
    category,
    query,
    ...opts
  })));
};