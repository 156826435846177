export const defaultQuery = {
  date_range: 'last_7_days'
};

// for API performance optimization wide date ranges are not allowed when app filter is unset
export const isForbiddenDateRange = _ref => {
  let {
    date_range,
    app_guid
  } = _ref;
  return date_range === 'last_30_days' && !app_guid;
};