import _isString from "lodash-es/isString";
import _flatten from "lodash-es/flatten";
import _filter from "lodash-es/filter";
// You can use this when setting a className prop to
// a list of classes without worrying about whether
// the list contains nil values.
const classes = function () {
  for (var _len = arguments.length, values = new Array(_len), _key = 0; _key < _len; _key++) {
    values[_key] = arguments[_key];
  }
  return _filter(_flatten(values), _isString).join(' ');
};
export default classes;