import _every from "lodash-es/every";
import _map from "lodash-es/map";
import _isEmpty from "lodash-es/isEmpty";
import "core-js/modules/esnext.iterator.map.js";
import { colors, useLoading, Layout, Button, HyperText, BarStack } from '@lookout/ui';
import React, { useMemo } from 'react';
import { format } from 'd3-format';
import { parse } from 'date-fns';
import { format as dateFormat } from '../../../lib/utils/date-fns-localized.js';
import protectedDeviceIcon from '../../../assets/images/protected-device.svg';
import I18n from '../../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const DetectionsChartTooltip = _ref => {
  let {
    date,
    count,
    total
  } = _ref;
  return _jsxs("div", {
    children: [_jsx("div", {
      className: "detections-date",
      children: dateFormat(parse(date, 'MM-dd-yyyy', new Date()), 'P')
    }), _jsx(HyperText, {
      className: "detections-count",
      content: I18n.t('dashboard.charts.detections.count_of_devices', {
        count,
        formattedCount: '{formattedCount}'
      }),
      children: _jsx(HyperText, {
        className: "formatted-count",
        css: {
          fontWeight: 'bolder'
        },
        content: I18n.numberToRounded(count, {
          precision: 0
        })
      }, "formattedCount")
    }), _jsx(HyperText, {
      className: "detections-share",
      content: I18n.t('dashboard.charts.detections.of_total', {
        count: '{detectionsShareCount}'
      }),
      children: _jsx(HyperText, {
        className: "detections-share-count",
        css: {
          fontWeight: 'bolder'
        },
        content: I18n.numberToPercentage(Math.round(count / total * 100), {
          precision: 0
        })
      }, "detectionsShareCount")
    })]
  });
};
const NoDataTooltip = () => _jsx("div", {
  className: "no-chart-data-tooltip",
  children: I18n.t('dashboard.charts.registrations.no_data')
});
const chartSubtitleStyle = {
  color: colors.black300,
  fontSize: 20,
  fontWeight: 'bolder',
  // HACK a special fix fo Safari to keep bottom panel stretched
  '& + div': {
    flex: 2
  }
};
const DetectionsChart = _ref2 => {
  let {
    deviceDetections,
    isPending,
    dateRange,
    titleStyle,
    height,
    onRegisterAppModalClick,
    apps
  } = _ref2;
  const {
    elementRef,
    loadingVisible
  } = useLoading(isPending);
  const isNoAppRegistered = useMemo(() => apps && _isEmpty(apps.value()), [apps]);
  const deviceDetectionResult = deviceDetections.value().values;
  const deviceDetectionsMaxCount = useMemo(() => Math.max(..._map(deviceDetectionResult, 'count')), [deviceDetectionResult]);
  const isEmptyDeviceDetectionData = useMemo(() => _every(deviceDetectionResult, item => !item.count), [deviceDetectionResult]);
  return _jsxs("figure", {
    ref: elementRef,
    css: {
      width: '100%',
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.white,
      '&.loading.in > :not(figcaption)': {
        opacity: 0.5
      },
      position: 'relative'
    },
    children: [_jsx("figcaption", {
      children: _jsx("h3", {
        css: titleStyle,
        className: "detections-chart-title",
        children: loadingVisible ? I18n.t('doc_title.loading') : I18n.t('dashboard.charts.detections.title', {
          dateRange: I18n.t(`dashboard.filters.${dateRange}`)
        })
      })
    }), _jsxs("div", {
      css: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: `1px solid ${colors.gray300}`,
        padding: '20px 20px 0 20px',
        flex: 2
      },
      children: [_jsx(HyperText, {
        className: "detections-chart-subtitle",
        content: I18n.t('dashboard.charts.detections.subtitle', {
          count: '{detectionsCount}'
        }),
        children: _jsx(HyperText, {
          className: "detections-count",
          css: chartSubtitleStyle,
          content: I18n.numberToRounded(deviceDetections.value().sum, {
            precision: 0
          })
        }, "detectionsCount")
      }), _jsx(BarStack, {
        className: "detections-chart",
        height: height,
        margins: {
          top: 20,
          right: 20,
          bottom: 40,
          left: 45
        },
        axisBottom: {
          tickFormat: val => dateFormat(parse(val, 'MM-dd-yyyy', new Date()), 'P')
        },
        x: _ref3 => {
          let {
            date
          } = _ref3;
          return date;
        },
        y: _ref4 => {
          let {
            count
          } = _ref4;
          return count;
        },
        ...(isEmptyDeviceDetectionData ? {
          data: deviceDetectionResult.map(item => ({
            ...item,
            count: 10
          })),
          color: colors.transparent,
          yNumTicks: 4,
          axisLeft: {
            label: I18n.t('dashboard.charts.detections.num_of_devices'),
            tickFormat: format('d')
          },
          tooltip: NoDataTooltip
        } : {
          data: deviceDetectionResult,
          yNumTicks: deviceDetectionsMaxCount > 4 ? 4 : deviceDetectionsMaxCount,
          axisLeft: {
            label: I18n.t('dashboard.charts.detections.num_of_devices'),
            tickFormat: format(deviceDetectionsMaxCount > 100 ? '.2~s' : 'd')
          },
          tooltip: _ref5 => {
            let {
              data: {
                date,
                count
              }
            } = _ref5;
            return _jsx(DetectionsChartTooltip, {
              date: date,
              count: count,
              total: deviceDetections.value().sum
            });
          }
        })
      })]
    }), isEmptyDeviceDetectionData && isNoAppRegistered && _jsxs(Layout, {
      css: {
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      },
      horizontal: true,
      children: [_jsx("img", {
        src: protectedDeviceIcon,
        css: {
          width: 100
        },
        alt: I18n.t('dashboard.docs.icon')
      }), _jsxs(Layout, {
        css: {
          margin: 20,
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        },
        vertical: true,
        children: [_jsx("span", {
          css: {
            marginBottom: 20,
            fontSize: 12
          },
          children: I18n.t('dashboard.charts.detections.register_first_app')
        }), _jsx(Button, {
          className: "register-app-button",
          onClick: onRegisterAppModalClick,
          children: I18n.t('apps_page.register_button')
        })]
      })]
    })]
  });
};
export default DetectionsChart;