import _identity from "lodash-es/identity";
import requestWithToken from '../../lib/api/request-with-token.js';
import { stringifyQuery } from '../../lib/utils/query-params.js';
import { appmgmtApiUrlFor, eventsApiUrlFor } from '../../lib/api/urls.js';
export const readApps = opts => requestWithToken(appmgmtApiUrlFor(`/${globalThis.cache.ent.guid}/apps`), opts);
export const readApp = _ref => {
  let {
    applicationGuid,
    opts
  } = _ref;
  return requestWithToken(appmgmtApiUrlFor(`/${globalThis.cache.ent.guid}/apps/${applicationGuid}`), opts);
};
export const readAppVersions = _ref2 => {
  let {
    applicationGuid,
    opts
  } = _ref2;
  return requestWithToken(appmgmtApiUrlFor(`/${globalThis.cache.ent.guid}/apps/${applicationGuid}/versions`), opts);
};
export const readAppToken = async _ref3 => {
  let {
    applicationGuid,
    opts
  } = _ref3;
  const {
    key
  } = await requestWithToken(appmgmtApiUrlFor(`/${globalThis.cache.ent.guid}/apps/${applicationGuid}/token`), {
    method: 'POST',
    body: JSON.stringify({}),
    opts
  });
  return key;
};
export const readAppEvents = _ref4 => {
  let {
    query,
    ...opts
  } = _ref4;
  return requestWithToken(eventsApiUrlFor(`/events?${stringifyQuery(query, null, _identity)}`), opts);
};
export const readDevice = _ref5 => {
  let {
    deviceGuid,
    opts
  } = _ref5;
  return requestWithToken(appmgmtApiUrlFor(`/devices/${deviceGuid}`), opts);
};
export const createApp = _ref6 => {
  let {
    data,
    ...opts
  } = _ref6;
  return requestWithToken(appmgmtApiUrlFor(`/${globalThis.cache.ent.guid}/apps`), {
    method: 'POST',
    body: JSON.stringify(data),
    ...opts
  });
};
export const updateApp = _ref7 => {
  let {
    applicationGuid,
    data,
    ...opts
  } = _ref7;
  return requestWithToken(appmgmtApiUrlFor(`/${globalThis.cache.ent.guid}/apps/${applicationGuid}`), {
    method: 'PUT',
    body: JSON.stringify(data),
    ...opts
  });
};
export const deleteApp = _ref8 => {
  let {
    applicationGuid,
    opts
  } = _ref8;
  return requestWithToken(appmgmtApiUrlFor(`/${globalThis.cache.ent.guid}/apps/${applicationGuid}`), {
    method: 'DELETE',
    opts
  });
};