import _isArray from "lodash-es/isArray";
import _castArray from "lodash-es/castArray";
import _compact from "lodash-es/compact";
import _uniq from "lodash-es/uniq";
import _map from "lodash-es/map";
import React from 'react';
import { Layout, Button, typography } from '@lookout/ui';
import { useNavigate } from 'react-router-dom';
import logoHorizontalCentered from '../../assets/images/logo-horizontal-centered.svg';
import I18n from '../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const styles = {
  overlay: {
    backgroundColor: 'white',
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 1e4
  },
  container: {
    margin: '60px auto',
    padding: '40px',
    a: {
      whiteSpace: 'nowrap'
    }
  },
  logoMark: {
    alignSelf: 'center',
    width: 210,
    marginBottom: 50
  },
  title: {
    fontSize: typography.toRem(30)
  }
};
const mapMessages = (messages, predicate) => _map(_uniq(_compact(_castArray(messages))), predicate);
const FullScreenErrorMessage = _ref => {
  let {
    className,
    title,
    description,
    resetErrorBoundary,
    showLogout = true
  } = _ref;
  const navigate = useNavigate();
  return _jsx(Layout, {
    className: className,
    css: [{
      justifyContent: 'center'
    }, styles.overlay],
    horizontal: true,
    children: _jsxs(Layout, {
      vertical: true,
      css: styles.container,
      children: [_jsx("img", {
        css: styles.logoMark,
        src: logoHorizontalCentered,
        alt: I18n.t('brand.name')
      }), _jsxs(Layout, {
        vertical: true,
        children: [_jsx("h1", {
          className: "error-title",
          css: styles.title,
          children: title
        }), _jsx("div", {
          className: "error-description",
          css: {
            a: {
              whiteSpace: 'nowrap'
            }
          },
          children: _isArray(description) ? mapMessages(description, message => _jsx("p", {
            children: message
          }, message)) : description
        }), _jsx(Layout, {
          horizontal: true,
          children: _jsxs("p", {
            children: [resetErrorBoundary && _jsx(Button, {
              className: "try-again-button",
              css: {
                marginRight: 20
              },
              onClick: () => resetErrorBoundary(),
              small: true,
              secondary: true,
              children: I18n.t('error.generic.try_again')
            }), showLogout && _jsx(Button, {
              className: "logout-button",
              onClick: () => navigate('/logout'),
              small: true,
              secondary: true,
              children: I18n.t('unauth.logout')
            })]
          })
        })]
      })]
    })
  });
};
export default FullScreenErrorMessage;