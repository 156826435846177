import _merge from "lodash-es/merge";
import _get from "lodash-es/get";
import * as lookoutRequest from '@lookout/request';
import { getToken } from './token-cache.js';

// The use of _.get(lookoutRequest, 'request') is to cheat on Webpack module
// resolution optimizer. Since we stub ES module object, we want to make sure
// we access module exports of that object and not from the standalone module file

const requestWithToken = (url, options) => {
  const headers = {
    Authorization: `Bearer ${getToken()}`
  };
  return _get(lookoutRequest, 'request')(url, _merge({
    headers
  }, options));
};
export default requestWithToken;