import _startCase from "lodash-es/startCase";
import _forEach from "lodash-es/forEach";
import _trim from "lodash-es/trim";
import _lowerCase from "lodash-es/lowerCase";
import "core-js/modules/esnext.iterator.map.js";
import { AtlasCube, MiscCog, LmsWifi } from '@lookout/dingbats';
import I18n from '../../i18n.js';
const specialCase = sourceWord => {
  if (!sourceWord) return '';
  const specialWords = [['ios', 'iOS'], ['os', 'OS'], ['mitm', 'MITM'], ['vpn', 'VPN']
  // to be continued
  ];
  const lcWord = _lowerCase(_trim(sourceWord));
  let returnWord = ''; // "no match found"

  _forEach(specialWords, _ref => {
    let [targetWord, correctWord] = _ref;
    if (lcWord === targetWord) {
      returnWord = correctWord;
      return false; // exit forEach
    }
  });
  return returnWord;
};

// transforms "STRING_THING" into "String thing". we get macro-case strings from the event feed.
// except some words should be capitalized specially (e.g., "iOS")
//
const macroCaseToTitleCase = s => {
  if (!s) return '';
  return s.trim().toLowerCase().split('_').map((word, index) => {
    const specialCaseWord = specialCase(word);
    if (index === 0) return specialCaseWord || _startCase(word);
    return specialCaseWord || word;
  }).join(' ');
};

// The assessment names come from this list:
// https://source.corp.lookout.com/lookout/message_schemas/blob/master/protobuf/schemas/mtp/threat.proto#L24

// The corresponding names and descriptions are cribbed from MES:
// https://source.corp.lookout.com/frontend/mtp-console/blob/master/config/locales/console/en.yml#L316

export const getAssessmentName = name => {
  const nameKey = `assessment.name.${name.toLowerCase()}`;
  return I18n.t(nameKey, {
    defaultValue: macroCaseToTitleCase(name)
  });
};
export const getAssessmentDesc = name => {
  const descKey = `assessment.desc.${name.toLowerCase()}`;
  return I18n.t(descKey, {
    missingBehavior: 'empty'
  }) ?? null;
};
export const getRawAssessmentCategory = eventType => ({
  APP_THREAT_DETECTED: 'application',
  APP_THREAT_RESOLVED: 'application',
  OS_THREAT_DETECTED: 'os',
  OS_THREAT_RESOLVED: 'os',
  NETWORK_THREAT_DETECTED: 'network',
  NETWORK_THREAT_RESOLVED: 'network'
})[eventType];
export const getAssessmentCategory = category => {
  const categoryKey = `assessment.category.${category}`;
  return I18n.t(categoryKey, {
    missingBehavior: 'empty'
  });
};
export const getIconFromCategory = category => ({
  application: AtlasCube,
  os: MiscCog,
  network: LmsWifi
})[category];