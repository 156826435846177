import _size from "lodash-es/size";
import "core-js/modules/esnext.iterator.map.js";
import React, { useState } from 'react';
import { Badge, Table, tableStyles, typography } from '@lookout/ui';
import classes from '../../lib/utils/classes.js';
import { tableLayoutStyles, mobileResponsiveMargin } from '../../lib/styles/layout-styles.js';
import I18n from '../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AppVersionsRow = _ref => {
  let {
    appVersion,
    isExpanded
  } = _ref;
  return isExpanded && appVersion.sdkVersions.map(sdkVersion => _jsxs("tr", {
    className: "expanded",
    children: [_jsx("td", {
      className: "app-version",
      css: typography.blush
    }), _jsx("td", {
      className: "sdk-version",
      children: sdkVersion
    })]
  }, sdkVersion));
};
const AppVersions = _ref2 => {
  let {
    appVersions
  } = _ref2;
  const [expandedVersion, setExpandedVersion] = useState(null);
  return _jsxs(_Fragment, {
    children: [_jsx("h2", {
      css: {
        margin: 40,
        ...mobileResponsiveMargin
      },
      className: "version-history-heading",
      children: I18n.t('app_details.version_history_heading')
    }), appVersions.value().length ? _jsxs(Table, {
      className: "versions-table",
      css: [tableStyles.striped, tableLayoutStyles.expandable, tableLayoutStyles.fullWidth, {
        'th, td': {
          minWidth: 200
        }
      }],
      children: [_jsx("thead", {
        children: _jsxs("tr", {
          children: [_jsx("th", {
            children: I18n.t('app_details.app_version')
          }), _jsx("th", {
            children: I18n.t('app_details.sdk_version')
          })]
        })
      }), _jsx("tbody", {
        children: appVersions.value().map(appVersion => {
          const isExpanded = appVersion.version === expandedVersion;
          return _jsxs(_Fragment, {
            children: [_jsxs("tr", {
              className: classes(_size(appVersion.sdkVersions) > 0 && 'expandable', isExpanded && 'expanded'),
              onClick: () => setExpandedVersion(isExpanded ? null : appVersion.version),
              children: [_jsx("td", {
                className: "app-version",
                children: appVersion.version
              }), _jsx("td", {
                className: "sdk-versions",
                css: {
                  width: '100%'
                },
                children: _jsxs(Badge, {
                  className: "sdk-versions-badge",
                  css: [{
                    fontWeight: 600,
                    fontSize: 11
                  }],
                  children: [appVersion.sdkVersions.length, ' ', I18n.t('app_details.versions', {
                    count: appVersion.sdkVersions.length
                  })]
                })
              })]
            }, appVersion.applicationGuid), _jsx(AppVersionsRow, {
              appVersion: appVersion,
              isExpanded: isExpanded
            })]
          });
        })
      })]
    }) : _jsx("p", {
      className: "app-versions-empty",
      css: {
        marginLeft: 40,
        marginRight: 40
      },
      children: I18n.t('app_details.version_history.none')
    })]
  });
};
export default AppVersions;