import { TokenChecker } from '@gnosis/oauth';
import { FullScreenSpinner, Layout, Notifications, useNotificationsState, NotificationsContext } from '@lookout/ui';
import React, { useEffect, Suspense } from 'react';
import { useResourceOnce } from '@lookout/suspense';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { readCache, readEnt } from './app-init-service.js';
// eslint-disable-next-line import/no-cycle
import FullScreenErrorMessage from './components/errors/fullscreen-error-message.js';
import SideNav from './components/nav/side-nav.js';
import { initMixpanel } from './lib/analytics/mixpanel-helper.js';
import { setToken } from './lib/api/token-cache.js';
import initSentry from './sentry-helper.js';
import NetworkErrorBoundary from './lib/network-error-boundary.js';
import I18n from './i18n.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const GlobalCache = _ref => {
  let {
    children,
    cache,
    ent
  } = _ref;
  if (!globalThis.cache) {
    globalThis.cache = {
      ...cache.value(),
      ent: ent.value(),
      date: Date.now()
    };
    initMixpanel();
  }
  return children;
};
const AppInit = () => {
  // react-router-dom hook that returns the next location we're about to
  // render. In child contexts, like within a page component, this hook may
  // return the current route.
  const {
    notifications,
    context: notificationsContext
  } = useNotificationsState();
  const cache = useResourceOnce(readCache);
  const ent = useResourceOnce(readEnt);
  useEffect(() => initSentry(), []);
  return _jsx(NetworkErrorBoundary, {
    fallbackRender: _ref2 => {
      let {
        resetErrorBoundary
      } = _ref2;
      return _jsx(FullScreenErrorMessage, {
        className: "app-init-error",
        title: I18n.t('error.generic.title'),
        resetErrorBoundary: resetErrorBoundary
      });
    },
    children: _jsx(Suspense, {
      fallback: _jsx("div", {
        className: "app-init-pending",
        children: _jsx(FullScreenSpinner, {})
      }),
      children: _jsx(GlobalCache, {
        cache: cache,
        ent: ent,
        children: _jsxs(Layout, {
          horizontal: true,
          css: {
            height: '100vh'
          },
          children: [_jsx(SideNav, {}), _jsxs(Layout, {
            css: {
              marginLeft: 200,
              overflow: 'auto',
              flexGrow: 2
            },
            children: [_jsx(Notifications, {
              className: "app-notifications",
              notifications: notifications,
              css: {
                position: 'sticky',
                top: 0,
                zIndex: 2
              }
            }), _jsx(NotificationsContext.Provider, {
              value: notificationsContext,
              children: _jsx(Outlet, {})
            })]
          })]
        })
      })
    })
  });
};
const AppAuth = () => {
  const location = useLocation();
  return globalThis.Cypress ? _jsx(AppInit, {}) : _jsx(TokenChecker, {
    currentRoute: location.pathname,
    tokenVendorUrl: globalThis.config.token_vendor_url,
    tokenRequest: {
      callbackUrl: globalThis.config.token_redirect_url,
      clientId: globalThis.config.token_vendor_client_id
    },
    requiredAuthorizations: ['sdk'],
    onValid: setToken,
    render: authorized => authorized ? _jsx(AppInit, {}) : _jsx(Navigate, {
      to: "/unauthorized"
    })
  });
};
const App = () => _jsx(ErrorBoundary, {
  fallbackRender: _ref3 => {
    let {
      resetErrorBoundary
    } = _ref3;
    return _jsx(FullScreenErrorMessage, {
      className: "app-init-error",
      title: I18n.t('error.generic.title'),
      resetErrorBoundary: resetErrorBoundary
    });
  },
  children: _jsx(HelmetProvider, {
    children: _jsx(AppAuth, {})
  })
});
export default App;