import _mapValues from "lodash-es/mapValues";
import _defaults from "lodash-es/defaults";
import _map from "lodash-es/map";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import React, { useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FilterSelect } from '@lookout/ui';
import { stringifyQuery, toPlainObject } from '../../lib/utils/query-params.js';
import I18n from '../../i18n.js';
import { defaultQuery, isForbiddenDateRange } from './dashboard-utils.js';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const dashboardFilterOptions = _ref => {
  let {
    apps
  } = _ref;
  return [{
    id: 'date_range',
    name: I18n.t('dashboard.filters.time'),
    singular: true,
    required: true,
    options: [{
      id: 'last_7_days',
      name: I18n.t('dashboard.filters.last_7_days')
    }, {
      id: 'last_30_days',
      name: I18n.t('dashboard.filters.last_30_days')
    }]
  }, {
    id: 'app_guid',
    name: I18n.t('dashboard.filters.app'),
    singular: true,
    options: _map(apps, _ref2 => {
      let {
        applicationGuid: id,
        appName: name
      } = _ref2;
      return {
        id,
        name
      };
    })
  }];
};
const DashboardFilter = _ref3 => {
  let {
    apps
  } = _ref3;
  const navigate = useNavigate();
  const options = useMemo(() => dashboardFilterOptions({
    apps: apps.value()
  }), [apps]);
  const [searchParams] = useSearchParams();
  const query = useMemo(() => _defaults({}, toPlainObject(searchParams), defaultQuery), [searchParams]);
  const value = useMemo(() => FilterSelect.deserialize(query, {
    options
  }), [options, query]);
  const onFilterChange = useCallback(val => {
    // remove values that match default query to avoid their exposure into browser URL
    const obj = _mapValues(FilterSelect.serialize(val, {
      options
    }), (v, key) => defaultQuery[key] === v ? undefined : v);
    if (isForbiddenDateRange(obj)) {
      delete obj.date_range;
    }
    const str = stringifyQuery(obj);
    navigate(str ? `?${str}` : '');
  }, [navigate, options]);
  return _jsx(FilterSelect, {
    className: "dashboard-filter",
    value: value,
    options: options,
    onChange: onFilterChange,
    isOptionDisabled: (_ref4, selected) => {
      let {
        id
      } = _ref4;
      return id === 'last_30_days' && !selected.find(_ref5 => {
        let {
          groupId
        } = _ref5;
        return groupId === 'app_guid';
      });
    }
  });
};
export default DashboardFilter;