import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from '@lookout/ui';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const containerStyle = {
  padding: '40px',
  position: 'relative'
};
export const Container = props => _jsx(Layout, {
  css: containerStyle,
  ...props,
  children: props.children
});
Container.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object
};