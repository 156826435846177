import { clearAll, TokenCallback, TokenChecker } from '@gnosis/oauth';
import React, { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, useRoutes } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import App from './app.js';
import AppDetailsPage from './components/app-details/app-details-page.js';
import AppsPage from './components/apps/apps-page.js';
import AndroidDocs from './components/docs/android.js';
import IosDocs from './components/docs/ios.js';
import FullScreenErrorMessage from './components/errors/fullscreen-error-message.js';
import Fallback from './components/micro/fallback.js';
import OffsiteRedirect from './components/micro/offsite-redirect.js';
import Dashboard from './components/dashboard/dashboard.js';
import { setToken } from './lib/api/token-cache.js';
import getPageTitle from './lib/utils/page-title.js';
import I18n from './i18n.js';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const Logout = () => {
  clearAll();
  setToken(null);
  return _jsx(OffsiteRedirect, {
    to: globalThis.config.logout_url
  });
};
const getAuthRoutes = () => [{
  path: '/login',
  element: _jsx(OffsiteRedirect, {
    to: globalThis.config.login_url
  })
}, {
  path: '/logout',
  element: _jsx(Logout, {})
}, {
  path: '/token',
  element: _jsx(TokenCallback, {
    onDecode: setToken,
    requiredAuthorizations: ['sdk'],
    render: (success, lastKnownRoute, authorized) => {
      if (success) {
        return authorized ? _jsx(Navigate, {
          to: lastKnownRoute
        }) : _jsx(Navigate, {
          to: "/unauthorized"
        });
      }
      return _jsx(Navigate, {
        to: "/login"
      });
    }
  })
}, {
  path: '/refresh-token',
  element: _jsx(TokenChecker, {
    tokenVendorUrl: globalThis.config.token_vendor_url,
    tokenRequest: {
      callbackUrl: globalThis.config.token_redirect_url,
      clientId: globalThis.config.token_vendor_client_id
    },
    requiredAuthorizations: ['sdk'],
    onValid: setToken,
    forceRefresh: true
  })
}, {
  path: '/unauthorized',
  element: _jsxs(HelmetProvider, {
    children: [_jsx(Helmet, {
      children: _jsx("title", {
        children: getPageTitle('unauth')
      })
    }), _jsx(FullScreenErrorMessage, {
      className: "unauthorized-page",
      title: I18n.t('unauth.title'),
      description: I18n.t('unauth.details')
    })]
  })
}];
const AppRoutes = () => {
  const element = useRoutes([...getAuthRoutes(), {
    path: '/',
    element: _jsx(App, {}),
    children: [{
      path: '/',
      element: _jsxs(_Fragment, {
        children: [_jsx(Helmet, {
          children: _jsx("title", {
            children: getPageTitle('home')
          })
        }), _jsx(Dashboard, {})]
      })
    }, {
      path: 'apps',
      element: _jsxs(_Fragment, {
        children: [_jsx(Helmet, {
          children: _jsx("title", {
            children: getPageTitle('apps')
          })
        }), _jsx(AppsPage, {})]
      })
    }, {
      path: 'apps/:applicationGuid',
      element: _jsxs(_Fragment, {
        children: [_jsx(Helmet, {
          children: _jsx("title", {
            children: getPageTitle('appDetails')
          })
        }), _jsx(Suspense, {
          fallback: _jsx(Fallback, {
            className: "app-details-pending",
            spinner: true
          }),
          children: _jsx(AppDetailsPage, {})
        })]
      })
    }, {
      path: 'docs/android',
      element: _jsxs(_Fragment, {
        children: [_jsx(Helmet, {
          children: _jsx("title", {
            children: getPageTitle('androidDocs')
          })
        }), _jsx(AndroidDocs, {})]
      })
    }, {
      path: 'docs/ios',
      element: _jsxs(_Fragment, {
        children: [_jsx(Helmet, {
          children: _jsx("title", {
            children: getPageTitle('iosDocs')
          })
        }), _jsx(IosDocs, {})]
      })
    }, {
      path: '*',
      element: _jsx(FullScreenErrorMessage, {
        className: "not-found-page",
        title: I18n.t('error.not_found.title')
      })
    }]
  }]);
  return element;
};
export default AppRoutes;