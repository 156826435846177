import _noop from "lodash-es/noop";
import { colors, Button, Modal, Panel, PanelHeader, PanelFooter, Textarea } from '@lookout/ui';
import React, { useMemo, useState, Suspense } from 'react';
import { useResourceOnce } from '@lookout/suspense';
import { JSONTree } from 'react-json-tree';
import jsonTreeTheme from '../../assets/json-tree-theme.js';
import { readDevice } from '../apps/apps-service.js';
import SelectToCopy from '../micro/select-to-copy.js';
import NetworkErrorBoundary from '../../lib/network-error-boundary.js';
import Fallback from '../micro/fallback.js';
import I18n from '../../i18n.js';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AppEventJson = _ref => {
  let {
    event,
    device
  } = _ref;
  const deviceResult = device?.value();
  const [textElement, setTextElement] = useState(null);
  const data = useMemo(() => ({
    ...deviceResult,
    ...event
  }), [deviceResult, event]);
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      className: "event-json-tree",
      css: {
        overflow: 'scroll',
        maxHeight: '40vh',
        border: `1px solid ${colors.gray100}`
      },
      children: _jsx(JSONTree, {
        data: data,
        theme: jsonTreeTheme,
        hideRoot: true
      })
    }), _jsx(Textarea, {
      value: JSON.stringify(data, null, 2),
      onChange: _noop,
      ref: setTextElement,
      css: {
        width: 0,
        height: 0,
        opacity: 0,
        padding: 0
      }
    }), _jsx(SelectToCopy, {
      textElement: textElement
    })]
  });
};

// The hidden text area is required by the copy to clipboard feature
const AppEventModal = _ref2 => {
  let {
    event,
    onClosed
  } = _ref2;
  const device = useResourceOnce(args => readDevice({
    deviceGuid: event.target.deviceId,
    ...args
  }));
  return _jsx(Modal, {
    className: "app-event-modal",
    onClosed: onClosed,
    children: _ref3 => {
      let {
        closeModal
      } = _ref3;
      return _jsxs(Panel, {
        onClose: () => closeModal(),
        children: [_jsx(PanelHeader, {
          children: I18n.t('json_modal.header')
        }), _jsx(NetworkErrorBoundary, {
          fallbackRender: () => _jsx(AppEventJson, {
            event: event
          }),
          children: _jsx(Suspense, {
            className: "app-event-modal-pending",
            fallback: _jsx(Fallback, {
              className: "app-event-modal-pending",
              spinner: true
            }),
            children: _jsx(AppEventJson, {
              event: event,
              device: device
            })
          })
        }), _jsx(PanelFooter, {
          children: _jsx(Button, {
            onClick: () => closeModal(),
            children: I18n.t('json_modal.ok_button')
          })
        })]
      });
    }
  });
};
export default AppEventModal;