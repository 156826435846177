import React, { Suspense, useEffect, useState } from 'react';
import { useResource, useResourceOnce } from '@lookout/suspense';
import { useParams } from 'react-router-dom';
import useTrackView from '../../lib/analytics/use-track-view.js';
import { mobileResponsiveMargin } from '../../lib/styles/layout-styles.js';
import Fallback from '../micro/fallback.js';
import { readApp, readAppEvents, readAppVersions } from '../apps/apps-service.js';
import NetworkErrorBoundary from '../../lib/network-error-boundary.js';
import I18n from '../../i18n.js';
import AppSummary from './app-summary.js';
import AppIdentifiers from './app-identifiers.js';
import AppVersions from './app-versions.js';
import AppEvents from './app-events.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AppDetailsPage = () => {
  useTrackView('app-details');
  const {
    applicationGuid
  } = useParams();
  const app = useResourceOnce(args => readApp({
    applicationGuid,
    ...args
  }));
  const appVersions = useResourceOnce(args => readAppVersions({
    applicationGuid,
    ...args
  }));
  const [appEvents, isAppEventsPending, appEventsTransition] = useResource(readAppEvents);
  const [streamPosition, setStreamPosition] = useState();
  useEffect(() => {
    appEventsTransition({
      query: {
        entId: globalThis.cache.ent.guid,
        applicationId: applicationGuid,
        limit: 10,
        streamPosition,
        order: 'desc'
      }
    });
  }, [appEventsTransition, applicationGuid, streamPosition]);
  return _jsxs("div", {
    className: "app-details-page",
    children: [_jsx(NetworkErrorBoundary, {
      fallbackRender: () => _jsx(Fallback, {
        className: "app-error",
        message: I18n.t('error.service.apps')
      }),
      children: _jsxs(Suspense, {
        fallback: _jsx(Fallback, {
          className: "app-pending",
          spinner: true
        }),
        children: [_jsx("div", {
          css: {
            margin: 30,
            ...mobileResponsiveMargin
          },
          children: _jsx(AppSummary, {
            app: app
          })
        }), _jsx("div", {
          css: {
            margin: 40,
            ...mobileResponsiveMargin
          },
          children: _jsx(AppIdentifiers, {
            app: app
          })
        })]
      })
    }), _jsx(NetworkErrorBoundary, {
      fallbackRender: () => _jsx(Fallback, {
        className: "app-error",
        message: I18n.t('error.service.apps')
      }),
      children: _jsx(Suspense, {
        children: _jsx(AppVersions, {
          appVersions: appVersions
        })
      })
    }), _jsx(NetworkErrorBoundary, {
      fallbackRender: () => _jsx(Fallback, {
        className: "app-events-error",
        message: I18n.t('error.service.app_events')
      }),
      children: _jsx(Suspense, {
        fallback: _jsx(Fallback, {
          className: "app-events-pending",
          spinner: true
        }),
        children: appEvents ? _jsx(AppEvents, {
          appEvents,
          setStreamPosition,
          isAppEventsPending
        }) : _jsx(Fallback, {
          className: "app-events-pending",
          spinner: true
        })
      })
    })]
  });
};
export default AppDetailsPage;