import _pick from "lodash-es/pick";
import _size from "lodash-es/size";
import React, { Suspense, useCallback, useMemo } from 'react';
import { Button, Modal, Panel, PanelHeader, PanelFooter, Layout, typography, FormikInput, FormikSelect } from '@lookout/ui';
import { Form, Formik, useField } from 'formik';
import { useResource } from '@lookout/suspense';
import NetworkErrorBoundary from '../../lib/network-error-boundary.js';
import I18n from '../../i18n.js';
import { createApp, updateApp } from './apps-service.js';
import { ResourceSuccess } from './apps-utils.js';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const PanelLayout = _ref => {
  let {
    isCreatePending,
    isUpdatePending,
    applicationGuid
  } = _ref;
  const [{
    value
  }] = useField('os');
  const osSelectOptions = useMemo(() => [{
    label: I18n.t('register_app.platform.ios'),
    value: 'ios'
  }, {
    label: I18n.t('register_app.platform.android'),
    value: 'android'
  }], []);
  return _jsxs(_Fragment, {
    children: [_jsx(PanelHeader, {
      children: applicationGuid ? I18n.t('register_app.dialog_header_edit') : I18n.t('register_app.dialog_header_add_new')
    }), _jsxs(Layout, {
      horizontal: true,
      children: [_jsx(FormikSelect, {
        className: "os-select",
        inputId: "os",
        name: "os",
        options: osSelectOptions,
        label: I18n.t('register_app.platform_section'),
        placeholder: I18n.t('register_app.select_placeholder_platform'),
        isDisabled: !!applicationGuid,
        menuPortalTarget: globalThis.document.body
      }), _jsxs("div", {
        css: {
          flexGrow: 1,
          marginLeft: 20
        },
        children: [value === 'android' && _jsx(FormikInput, {
          className: "android-app-id",
          name: "packageName",
          label: I18n.t('register_app.platform_id_android'),
          placeholder: I18n.t('register_app.platform_id_android'),
          inputStyle: {
            width: '100%'
          },
          disabled: !!applicationGuid
        }), value === 'ios' && _jsx(FormikInput, {
          className: "ios-app-id",
          name: "packageName",
          label: I18n.t('register_app.platform_id_ios'),
          placeholder: I18n.t('register_app.platform_id_ios'),
          inputStyle: {
            width: '100%'
          },
          disabled: !!applicationGuid
        })]
      })]
    }), _jsx(FormikInput, {
      className: "app-name-input",
      name: "appName",
      placeholder: I18n.t('register_app.input_placeholder_app_name'),
      label: I18n.t('register_app.input_label_app_name'),
      labelStyle: {
        ...typography.h2,
        display: 'inline-flex'
      },
      inputStyle: {
        width: '100%'
      },
      css: {
        marginTop: 15
      }
    }), _jsx(FormikInput, {
      className: "app-comments",
      name: "comment",
      placeholder: I18n.t('register_app.input_placeholder_comments'),
      label: I18n.t('register_app.input_label_comments'),
      labelStyle: {
        ...typography.h2,
        display: 'inline-flex'
      },
      inputStyle: {
        width: '100%'
      },
      css: {
        marginTop: 15
      }
    }), _jsxs(PanelFooter, {
      children: [_jsx(Button, {
        type: "reset",
        className: "cancel-button",
        autoFocus: true,
        link: true,
        children: I18n.t('buttons.cancel')
      }), _jsx(Button, {
        className: "save-button",
        disabled: isCreatePending || isUpdatePending,
        type: "submit",
        children: isCreatePending || isUpdatePending ? I18n.t('buttons.saving') : I18n.t('buttons.save_changes')
      })]
    })]
  });
};
const RegisterAppModal = _ref2 => {
  let {
    options = {},
    onClosed
  } = _ref2;
  const {
    applicationGuid,
    appName,
    comment,
    os,
    packageName
  } = options;
  const [create, isCreatePending, startCreateApp] = useResource(createApp);
  const [update, isUpdatePending, startUpdateApp] = useResource(updateApp);
  const validate = useCallback(_ref3 => {
    let {
      os: platform,
      packageName: appPackageName,
      appName: name
    } = _ref3;
    let errors = {};
    if (!platform) {
      errors = {
        ...errors,
        os: I18n.t('error.form.required')
      };
    }
    if (_size(appPackageName) <= 0) {
      errors = {
        ...errors,
        packageName: I18n.t('error.form.required')
      };
    }
    if (_size(appPackageName) > 110) {
      errors = {
        ...errors,
        packageName: I18n.t('error.form.max_length')
      };
    }
    if (_size(name) <= 0) {
      errors = {
        ...errors,
        appName: I18n.t('error.form.required')
      };
    }
    if (_size(name) > 30) {
      errors = {
        ...errors,
        appName: I18n.t('error.form.app_name', {
          count: 30
        })
      };
    }
    return errors;
  }, []);
  return _jsx(Formik, {
    initialValues: {
      os: os || 'android',
      packageName: packageName || '',
      appName: appName || '',
      comment: comment || ''
    },
    onSubmit: data => {
      if (applicationGuid) startUpdateApp({
        applicationGuid,
        data: _pick(data, ['appName', 'comment'])
      });else startCreateApp({
        data: {
          ..._pick(data, ['os', 'packageName', 'appName', 'comment']),
          userEmail: globalThis.cache.admin.email,
          userName: globalThis.cache.admin.name
        }
      });
    },
    validate: validate,
    validateOnBlur: false,
    validateOnChange: false,
    children: _jsx(Modal, {
      className: `${applicationGuid ? 'edit-app' : 'register-app'}-modal`,
      onClosed: onClosed,
      children: _ref4 => {
        let {
          closeModal
        } = _ref4;
        return _jsx(Panel, {
          className: applicationGuid ? 'edit-app' : 'register-app',
          onClose: () => closeModal(),
          as: Form,
          children: _ref5 => {
            let {
              notifyError
            } = _ref5;
            return _jsxs(_Fragment, {
              children: [(create || update) && _jsx(NetworkErrorBoundary, {
                resetKeys: [create, update],
                onError: () => notifyError(I18n.t('error.service.apps')),
                children: _jsx(Suspense, {
                  children: _jsx(ResourceSuccess, {
                    resource: create || update,
                    onSuccess: result => closeModal(result)
                  })
                })
              }), _jsx(PanelLayout, {
                isCreatePending,
                isUpdatePending,
                applicationGuid
              })]
            });
          }
        });
      }
    })
  });
};
export default RegisterAppModal;