import React from 'react';
import PropTypes from 'prop-types';
import { Layout, colors } from '@lookout/ui';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const circleContainerStyle = {
  width: '2.5em',
  height: '2.5em',
  fontSize: '14px',
  color: colors.gray100,
  backgroundColor: colors.blueGray50,
  borderRadius: '50%'
};
const CircleText = props => _jsx(Layout, {
  horizontal: true,
  css: [{
    alignItems: 'center',
    justifyContent: 'center'
  }, circleContainerStyle],
  ...props
});
CircleText.propTypes = {
  className: PropTypes.string
};
export default CircleText;