import React from 'react';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@lookout/ui';

/*
  Installs default (gnosis) components into rendered MDX markdown. See here for
  how we're supporting Markdown: https://github.com/mdx-js/mdx

  Sample usage:
    import MyMarkdownContent from './myMarkdown.md'
    import {Markdownify} from './markdownify'

    export default (
      <section>
        <Markdownify md={MyMarkdownContent} />
      </section>
    )
*/
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CalloutBox = styled.div({
  padding: '8px',
  border: `1px solid ${colors.primary}`,
  borderRadius: '2px',
  background: colors.lightGray100
});
const defaultComponents = {
  blockquote: CalloutBox
};
const Markdownify = _ref => {
  let {
    md: Markdown
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsx(Global, {
      styles: {
        'li ul': {
          listStyle: 'circle'
        }
      }
    }), _jsx(Markdown, {
      components: defaultComponents
    })]
  });
};
export default Markdownify;