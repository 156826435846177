import React from 'react';
import PropTypes from 'prop-types';
import { colors, Layout } from '@lookout/ui';
import I18n from '../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AppIdentifierRow = _ref => {
  let {
    name,
    id,
    className,
    rightMargin
  } = _ref;
  return _jsxs(Layout, {
    className: `${className}-row`,
    horizontal: true,
    children: [_jsx("div", {
      className: `${className}-title`,
      css: {
        color: colors.darkGray200,
        fontSize: 13,
        marginRight: rightMargin,
        marginBottom: 10
      },
      children: name
    }), _jsx("div", {
      className: className,
      css: {
        fontSize: 14
      },
      children: id
    })]
  });
};
AppIdentifierRow.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  rightMargin: PropTypes.number
};
const AppIdentifiers = _ref2 => {
  let {
    app
  } = _ref2;
  const {
    enterpriseGuid,
    applicationGuid
  } = app.value();
  return _jsxs(_Fragment, {
    children: [_jsx("h2", {
      className: "app-identifiers-title",
      children: I18n.t('app_details.app_identifiers')
    }), _jsx("p", {
      children: I18n.t('app_details.app_identifiers')
    }), _jsxs(Layout, {
      className: "app-identifiers-info",
      css: {
        maxWidth: 420
      },
      vertical: true,
      children: [_jsx(AppIdentifierRow, {
        name: I18n.t('app_details.app_id'),
        id: applicationGuid,
        className: "application-id",
        rightMargin: 40
      }), _jsx(AppIdentifierRow, {
        name: I18n.t('app_details.ent_id'),
        id: enterpriseGuid,
        className: "enterprise-id"
        // This is to adjust the margin for ent-id column display
        // because of the text length of the left column is small
        // which makes to look bit off with the first row,
        // TODO: Can be updated to use the table and make it look better
        ,
        rightMargin: 47
      })]
    })]
  });
};
AppIdentifiers.propTypes = {
  app: PropTypes.shape({
    value: PropTypes.func
  })
};
export default AppIdentifiers;