import React from 'react';
import PropTypes from 'prop-types';
import { colors, typography } from '@lookout/ui';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DoubleHeader = _ref => {
  let {
    className,
    suppressAutoshift,
    ...props
  } = _ref;
  let {
    top,
    bottom
  } = props;
  if (!top && bottom && !suppressAutoshift) {
    top = bottom;
    bottom = null;
  }
  return _jsxs("div", {
    className: className,
    css: typography.blush,
    children: [top && _jsxs(_Fragment, {
      children: [_jsx("span", {
        css: {
          fontSize: 14
        },
        className: "double-header-top",
        children: top
      }), _jsx("br", {})]
    }), bottom && _jsx("span", {
      css: {
        fontSize: 12,
        color: colors.darkGray300
      },
      className: "double-header-bottom",
      children: bottom
    })]
  });
};
DoubleHeader.propTypes = {
  top: PropTypes.node,
  bottom: PropTypes.node,
  className: PropTypes.string,
  suppressAutoshift: PropTypes.bool
};