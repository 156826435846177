import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
import React from 'react';
import PropTypes from 'prop-types';
import { Layout, colors } from '@lookout/ui';
import CircleText from '../micro/circle-text.js';
import I18n from '../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
function parseUserName(userName) {
  const parsed = {
    initials: null,
    firstName: null
  };
  if (userName) {
    const words = userName.split(' ');
    if (words.length > 0) parsed.firstName = words[0].trim().slice(0, 10);
    if (words.length <= 2) parsed.initials = words.reduce((initials, word) => initials + word.charAt(0), '');
  }
  return parsed;
}
const SideNavFooter = _ref => {
  let {
    userName
  } = _ref;
  const {
    initials,
    firstName
  } = parseUserName(userName);
  return _jsxs(Layout, {
    css: {
      color: colors.lightGray100,
      alignItems: 'center'
    },
    horizontal: true,
    children: [initials && _jsx(CircleText, {
      className: "admin-initials",
      css: {
        marginRight: '0.5em'
      },
      children: initials
    }), ' ', _jsx("span", {
      className: "admin-name",
      children: firstName || I18n.t('user_menu.title')
    })]
  });
};
export default SideNavFooter;
SideNavFooter.propTypes = {
  userName: PropTypes.string
};