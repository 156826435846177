import _head from "lodash-es/head";
import { FilterSelect, Layout, useNotifications } from '@lookout/ui';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useResource } from '@lookout/suspense';
import useTrackView from '../../lib/analytics/use-track-view.js';
import { mobileResponsivePadding } from '../../lib/styles/layout-styles.js';
import { belowQuery, screenXsMax } from '../../lib/styles/breakpoints.js';
import Fallback from '../micro/fallback.js';
import ActionButton from '../micro/action-button.js';
import NetworkErrorBoundary from '../../lib/network-error-boundary.js';
import I18n from '../../i18n.js';
import { readApps } from './apps-service.js';
import AppsList from './apps-list.js';
import DeleteAppModal from './delete-app-modal.js';
import GetKeyModal from './get-key-modal.js';
import RegisterAppModal from './register-app-modal.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const REGISTER_APP_MODAL = 'REGISTER_APP_MODAL';
const GET_KEY_MODAL = 'GET_KEY_MODAL';
const EDIT_APP_MODAL = 'EDIT_APP_MODAL';
const DELETE_APP_MODAL = 'DELETE_APP_MODAL';
const tableRowActionModals = {
  GET_KEY_MODAL,
  EDIT_APP_MODAL,
  DELETE_APP_MODAL
};
const initialModalState = {
  type: null,
  options: null
};
const AppsPage = () => {
  useTrackView('apps');
  const [apps, isPending, readAppsTransition] = useResource(readApps);
  const [filter, setFilter] = useState(null);
  const [modal, setModal] = useState(initialModalState);
  const setModalCallback = useCallback(payload => setModal(payload), []);
  const {
    notifySuccess
  } = useNotifications();
  useEffect(() => {
    readAppsTransition();
  }, [readAppsTransition]);
  return _jsxs("div", {
    className: "apps-page",
    children: [_jsxs(Layout, {
      css: {
        padding: 40,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...mobileResponsivePadding
      },
      horizontal: true,
      children: [_jsx("h1", {
        css: {
          margin: 0
        },
        children: I18n.t('apps_page.page_header')
      }), _jsx(ActionButton, {
        className: "register-an-app",
        onClick: () => setModal({
          type: REGISTER_APP_MODAL
        }),
        children: I18n.t('apps_page.register_button')
      })]
    }), _jsx("div", {
      css: {
        marginBottom: 20,
        padding: '0 40px',
        [belowQuery({
          max: screenXsMax
        })]: {
          padding: '0px 20px'
        }
      },
      children: _jsx(FilterSelect, {
        className: "apps-filter",
        placeholder: I18n.t('apps_page.filter_placeholder'),
        getNewOptionData: () => ({
          // TODO: Add query param support
          groupId: 'q',
          groupName: I18n.t('apps_page.filter_name'),
          description: I18n.t('apps_page.filter_description')
        }),
        onChange: options => setFilter(_head(options)?.name)
      })
    }), _jsx(NetworkErrorBoundary, {
      fallback: _jsx(Fallback, {
        className: "apps-list-error",
        message: I18n.t('error.service.apps')
      }),
      children: _jsx(Suspense, {
        fallback: _jsx(Fallback, {
          className: "apps-list-pending",
          spinner: true
        }),
        children: apps ? _jsx(AppsList, {
          filter: filter,
          modals: tableRowActionModals,
          setModal: setModalCallback,
          apps: apps,
          isPending: isPending
        }) : _jsx(Fallback, {
          className: "apps-list-pending",
          spinner: true
        })
      })
    }), (modal.type === REGISTER_APP_MODAL || modal.type === EDIT_APP_MODAL) && _jsx(RegisterAppModal, {
      options: modal.options,
      onClosed: result => {
        setModal(initialModalState);
        if (result) {
          notifySuccess(modal.options?.applicationGuid ? I18n.t('apps_page.alert.edit_app_success', result) : I18n.t('apps_page.alert.new_app_success', result));
          readAppsTransition();
        }
      }
    }), modal.type === GET_KEY_MODAL && _jsx(GetKeyModal, {
      options: modal.options,
      onClosed: () => setModal(initialModalState)
    }), modal.type === DELETE_APP_MODAL && _jsx(DeleteAppModal, {
      options: modal.options,
      onClosed: result => {
        setModal(initialModalState);
        if (result) {
          notifySuccess(I18n.t('apps_page.alert.delete_success', {
            appName: modal.options.appName
          }));
          readAppsTransition();
        }
      }
    })]
  });
};
export default AppsPage;