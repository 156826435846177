import React from 'react';
import { Container } from '../micro/container.js';
import Markdownify from '../micro/markdownify.js';
import AndroidMarkdown from './android.md';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const AndroidDocs = () => _jsx(Container, {
  className: "android-docs-page",
  children: _jsx(Markdownify, {
    md: AndroidMarkdown
  })
});
export default AndroidDocs;