import 'regenerator-runtime/runtime.js';
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch.js';
import 'url-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Style from './style.js';
import AppRoutes from './app-routes.js';
import './favicons.js';
/* eslint-disable import/order, spaced-comment */
///////////////
/////////////////////////////////////////
//////////
/* eslint-enable spaced-comment */

/* eslint-disable spaced-comment */
///////////////
/////////////////////////
///////////////////////////////////////
////////////////////////////////////
//////////////
///////////////////
////////////////////////
//////////////
//////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
/////////////////////////////////
//////////////////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
//////////
////////
//////
////

//////////
/* eslint-enable spaced-comment */
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
createRoot(globalThis.document.getElementById('content')).render(_jsxs(_Fragment, {
  children: [_jsx(Style, {}), _jsx(BrowserRouter, {
    children: _jsx(AppRoutes, {})
  })]
}));