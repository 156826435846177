import { tableFnStyles, tableStyleConstants } from '@lookout/ui';
import { belowQuery, screenXsMax } from './breakpoints.js';
export const contentPadding = 40;
export const mobileContentPadding = contentPadding / 2;
export const tableLayoutStyles = {
  fullWidth: {
    width: '100%',
    marginBottom: contentPadding / 2,
    'th, td': {
      ':first-of-type': {
        paddingLeft: contentPadding
      },
      ':last-of-type': {
        paddingRight: contentPadding
      },
      [belowQuery({
        max: screenXsMax
      })]: {
        ':first-of-type': {
          paddingLeft: mobileContentPadding
        },
        ':last-of-type': {
          paddingRight: mobileContentPadding
        }
      }
    }
  },
  expandable: {
    ...tableFnStyles.expandable({
      cellHorizontalPadding: contentPadding,
      thumbStyle: {
        [belowQuery({
          max: screenXsMax
        })]: {
          paddingLeft: mobileContentPadding - tableStyleConstants.expandThumbWidth
        }
      }
    })
  }
};
export const mobileResponsiveMargin = {
  [belowQuery({
    max: screenXsMax
  })]: {
    margin: 20
  }
};
export const mobileResponsivePadding = {
  [belowQuery({
    max: screenXsMax
  })]: {
    padding: '40px 20px'
  }
};