import _noop from "lodash-es/noop";
import { Button, Modal, Panel, PanelFooter, PanelHeader, Layout, Spinner, Textarea } from '@lookout/ui';
import React, { useState, Suspense } from 'react';
import { useResourceOnce } from '@lookout/suspense';
import Fallback from '../micro/fallback.js';
import SelectToCopy from '../micro/select-to-copy.js';
import NetworkErrorBoundary from '../../lib/network-error-boundary.js';
import I18n from '../../i18n.js';
import { readAppToken } from './apps-service.js';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const CopyKey = _ref => {
  let {
    appKey
  } = _ref;
  const [textRef, setTextRef] = useState(null);
  return appKey && _jsxs(_Fragment, {
    children: [_jsx(Textarea, {
      className: "app-key",
      cols: "80",
      css: {
        width: '100%'
      },
      onChange: _noop,
      ref: setTextRef,
      rows: "8",
      value: appKey.value()
    }), _jsx(SelectToCopy, {
      textElement: textRef
    })]
  });
};
const GetKeyModal = _ref2 => {
  let {
    options = {},
    onClosed
  } = _ref2;
  const {
    applicationGuid
  } = options;
  const readApp = useResourceOnce(args => readAppToken({
    applicationGuid,
    ...args
  }));
  return _jsx(Modal, {
    className: "get-key-modal",
    onClosed: onClosed,
    children: _ref3 => {
      let {
        closeModal
      } = _ref3;
      return _jsxs(Panel, {
        onClose: () => closeModal(),
        children: [_jsx(PanelHeader, {
          children: I18n.t('key_modal.header')
        }), _jsx(NetworkErrorBoundary, {
          fallback: _jsx(Fallback, {
            className: "get-key-error",
            message: I18n.t('error.service.apps')
          }),
          children: _jsx(Suspense, {
            fallback: _jsx(Layout, {
              className: "app-key-pending",
              css: {
                minHeight: '33vh',
                alignItems: 'center',
                justifyContent: 'center'
              },
              horizontal: true,
              children: _jsx(Spinner, {
                size: 10
              })
            }),
            children: readApp ? _jsx(CopyKey, {
              appKey: readApp
            }) : _jsx(Fallback, {
              className: "token-loading",
              spinner: true
            })
          })
        }), _jsx(PanelFooter, {
          children: _jsx(Button, {
            onClick: () => closeModal(),
            children: I18n.t('key_modal.ok_button')
          })
        })]
      });
    }
  });
};
export default GetKeyModal;