import React from 'react';
import { Layout, SelectCopy, colors } from '@lookout/ui';
import { MiscSignCheck } from '@lookout/dingbats';
import I18n from '../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SelectToCopy = _ref => {
  let {
    textElement
  } = _ref;
  return _jsx(SelectCopy, {
    textElement: textElement,
    children: (copyHandler, selectSuccess) => {
      const textStyle = {
        color: selectSuccess ? colors.primary : colors.darkGray100,
        cursor: 'pointer'
      };
      return (
        // TODO: fix this eslint warning by satisfying it
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        _jsx("h3", {
          css: {
            display: 'inline-flex'
          },
          onClick: copyHandler,
          children: _jsxs(Layout, {
            css: {
              // Height value for layout prevents text shift when icon
              // appears
              height: '32px',
              display: 'inline-flex',
              alignItems: 'center'
            },
            horizontal: true,
            children: [selectSuccess && _jsx(MiscSignCheck, {
              css: {
                marginLeft: '-6px',
                fill: colors.primary,
                width: '32px',
                height: '32px'
              }
            }), _jsx("span", {
              css: textStyle,
              children: selectSuccess ? I18n.t('select_copy.copied') : I18n.t('select_copy.action_text')
            })]
          })
        })
      );
    }
  });
};
export default SelectToCopy;