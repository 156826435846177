import _isEmpty from "lodash-es/isEmpty";
import _lowerCase from "lodash-es/lowerCase";
import _startCase from "lodash-es/startCase";
import _uniq from "lodash-es/uniq";
import "core-js/modules/esnext.iterator.map.js";
import { colors, tableStyles, Layout, Table, useLoading } from '@lookout/ui';
import { parseISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { format } from '../../lib/utils/date-fns-localized.js';
import { mobileResponsiveMargin } from '../../lib/styles/layout-styles.js';
import Fallback from '../micro/fallback.js';
import { DoubleHeader } from '../micro/double-header.js';
import ActionButton from '../micro/action-button.js';
import I18n from '../../i18n.js';
import { getAssessmentName, getRawAssessmentCategory, getAssessmentCategory, getIconFromCategory } from './assessment-strings.js';
import AppEventModal from './app-event-modal.js';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const EventDetailsCell = _ref => {
  let {
    event
  } = _ref;
  if (event.assessments?.length === 0) return null;
  const rawCategory = getRawAssessmentCategory(event.type);
  const Icon = getIconFromCategory(rawCategory);
  return _jsxs(Layout, {
    className: "event-details-cell",
    css: {
      alignItems: 'center',
      display: 'inline-flex'
    },
    horizontal: true,
    children: [Icon && _jsx(Icon, {
      css: {
        height: 32,
        fill: colors.blueGray100,
        marginLeft: 8,
        width: 32
      }
    }), _jsx(DoubleHeader, {
      top: getAssessmentCategory(rawCategory),
      bottom: _uniq(event.assessments?.map(assessment => getAssessmentName(assessment.classification))).join(', ')
    })]
  });
};
const AppEventsRow = _ref2 => {
  let {
    event,
    setSelectedAppEvent
  } = _ref2;
  return useMemo(() => _jsxs("tr", {
    className: "app-events-row",
    onClick: () => {
      setSelectedAppEvent(event);
    },
    children: [_jsx("td", {
      className: "app-event-type",
      css: {
        whiteSpace: 'nowrap'
      },
      children: _startCase(_lowerCase(event.type))
    }), _jsx("td", {
      className: "app-event-device-id",
      css: {
        whiteSpace: 'nowrap'
      },
      children: event.target.deviceId
    }), _jsx("td", {
      className: "app-event-details",
      children: _jsx(EventDetailsCell, {
        event: event
      })
    }), _jsx("td", {
      className: "app-event-time",
      children: _jsx(DoubleHeader, {
        top: format(parseISO(event.serverTime), 'M/d/yyyy'),
        bottom: format(parseISO(event.serverTime), 'p')
      })
    })]
  }), [event, setSelectedAppEvent]);
};
const AppEvents = _ref3 => {
  let {
    appEvents,
    setStreamPosition,
    isAppEventsPending
  } = _ref3;
  const [selectedAppEvent, setSelectedAppEvent] = useState(null);
  const {
    events,
    moreEvents,
    streamPosition
  } = appEvents.value();
  const {
    elementRef,
    loadingVisible
  } = useLoading(isAppEventsPending);
  return _jsxs(_Fragment, {
    children: [!_isEmpty(events) ? _jsxs(_Fragment, {
      children: [_jsx("h2", {
        css: {
          margin: '40px 40px 20px',
          ...mobileResponsiveMargin
        },
        children: I18n.t('eventlist.heading')
      }), _jsxs("div", {
        css: {
          overflow: 'auto'
        },
        children: [_jsxs(Table, {
          className: "app-events-table",
          css: [tableStyles.default, tableStyles.striped, tableStyles.hover, tableStyles.cursorPointer, {
            fontSize: 14,
            marginBottom: 40,
            width: '100%',
            'thead tr th': {
              whiteSpace: 'nowrap'
            },
            'thead tr th, tbody tr td': {
              minWidth: 200,
              paddingLeft: 40,
              paddingRight: 40
            }
          }],
          children: [_jsx("thead", {
            children: _jsxs("tr", {
              children: [_jsx("th", {
                children: I18n.t('eventlist.column.event')
              }), _jsx("th", {
                children: I18n.t('eventlist.column.device')
              }), _jsx("th", {
                children: I18n.t('eventlist.column.event_detail')
              }), _jsx("th", {
                children: I18n.t('eventlist.column.time')
              })]
            })
          }), _jsx("tbody", {
            ref: elementRef,
            children: events && events.map(event => _jsx(AppEventsRow, {
              event: event,
              setSelectedAppEvent: setSelectedAppEvent
            }, event.eventId))
          })]
        }), _jsx(Layout, {
          css: {
            padding: 40,
            justifyContent: 'center'
          },
          horizontal: true,
          children: moreEvents ? _jsx(ActionButton, {
            className: "load-more-events",
            disabled: loadingVisible,
            onClick: () => setStreamPosition(streamPosition),
            children: loadingVisible ? I18n.t('eventlist.loading_events') : I18n.t('eventlist.load_more_events')
          }) : _jsx("span", {
            className: "no-more-events",
            children: I18n.t('eventlist.no_more_events')
          })
        })]
      })]
    }) : _jsx(Fallback, {
      className: "app-events-filtered-empty",
      message: I18n.t('eventlist.no_events')
    }), selectedAppEvent && _jsx(AppEventModal, {
      event: selectedAppEvent,
      onClosed: () => setSelectedAppEvent(null)
    })]
  });
};
export default AppEvents;