import styled from '@emotion/styled';
import { Button, colors } from '@lookout/ui';
const ActionButton = styled(Button)({
  textTransform: 'uppercase',
  padding: '8px 15px',
  color: colors.blueGray100,
  background: colors.lightBlueGray300,
  ':hover': {
    color: '#fff',
    background: colors.blueGray100
  },
  ':disabled': {
    color: colors.darkGray300,
    background: colors.lightGray100
  }
}, _ref => {
  let {
    size
  } = _ref;
  return {
    fontSize: size === 'small' || size === 'mini' ? '11px' : undefined
  };
});
export default ActionButton;