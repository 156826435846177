import React from 'react';
import { useMatch, Link, NavLink } from 'react-router-dom';
import { Layout, NavPanel, NavPanelMenu, NavItem, NavPanelHeader, NavPanelFooter } from '@lookout/ui';
import { colors } from '@lookout/design';
import { MiscHome, MesApps, MesSupport } from '@lookout/dingbats';
import logoHorizontalInverted from '../../assets/images/logo-horizontal-inverted.svg';
import I18n from '../../i18n.js';
import SideNavFooter from './side-nav-footer.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const menuIconStyle = {
  width: 24,
  height: 24,
  marginRight: 16,
  verticalAlign: 'text-bottom',
  fill: 'currentColor'
};
const menuTextStyle = {
  lineHeight: '24px'
};
const SideNav = () => {
  const matchDefault = useMatch('/');
  const matchApps = useMatch('/apps/*');
  return _jsxs(NavPanel, {
    children: [_jsx(NavPanelHeader, {
      children: _jsx(Link, {
        className: "logo-link",
        to: "/",
        state: null,
        css: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 130,
          padding: 30
        },
        children: _jsxs(Layout, {
          vertical: true,
          children: [_jsx("img", {
            src: logoHorizontalInverted,
            alt: I18n.t('brand.lookout'),
            css: {
              width: '100%'
            }
          }), _jsx("span", {
            css: {
              fontSize: '9.5px',
              textTransform: 'uppercase',
              color: colors.white
            },
            children: I18n.t('main_menu.app_name')
          })]
        })
      })
    }), _jsxs(NavPanelMenu, {
      children: [_jsxs(NavItem, {
        className: "home-link",
        to: "/",
        as: NavLink,
        active: matchDefault,
        children: [_jsx(MiscHome, {
          css: menuIconStyle
        }), _jsx("span", {
          css: menuTextStyle,
          children: I18n.t('main_menu.home')
        })]
      }), _jsxs(NavItem, {
        className: "apps-link",
        to: "/apps",
        as: NavLink,
        active: matchApps,
        children: [_jsx(MesApps, {
          css: menuIconStyle
        }), _jsx("span", {
          css: menuTextStyle,
          children: I18n.t('main_menu.apps')
        })]
      }), _jsxs(NavItem, {
        href: I18n.t('main_menu.support_link'),
        target: "_blank",
        className: "support-link",
        children: [_jsx(MesSupport, {
          css: menuIconStyle
        }), _jsx("span", {
          css: menuTextStyle,
          children: I18n.t('main_menu.support')
        })]
      })]
    }), _jsx(NavPanelFooter, {
      selectControlStyle: {
        '&:hover, &:focus, &:active': {
          '.admin-initials': {
            backgroundColor: colors.darkGray100
          }
        }
      },
      children: _jsx(NavItem, {
        children: _jsx(SideNavFooter, {
          userName: globalThis.cache.admin.name
        })
      })
    }), _jsx(NavPanelMenu, {
      footer: true,
      children: _jsx(NavItem, {
        className: "edit-preferences",
        to: "/logout",
        as: NavLink,
        children: I18n.t('user_menu.logout')
      })
    })]
  });
};
export default SideNav;