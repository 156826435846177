import React, { Suspense } from 'react';
import { useResource } from '@lookout/suspense';
import DOMPurify from 'dompurify';
import { Modal, Panel, PanelHeader, PanelDescription, PanelFooter, Button, HyperText } from '@lookout/ui';
import { WarningButton } from '../micro/warning-button.js';
import NetworkErrorBoundary from '../../lib/network-error-boundary.js';
import I18n from '../../i18n.js';
import { deleteApp } from './apps-service.js';
import { ResourceSuccess } from './apps-utils.js';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const DeleteAppModal = _ref => {
  let {
    options,
    onClosed
  } = _ref;
  const [deleteApplication, isPending, startDeleteApp] = useResource(deleteApp);
  const {
    applicationGuid,
    appName
  } = options;
  return _jsx(Modal, {
    className: "delete-app-modal",
    onClosed: onClosed,
    children: _ref2 => {
      let {
        closeModal
      } = _ref2;
      return _jsx(Panel, {
        onSubmit: () => startDeleteApp({
          applicationGuid
        }),
        onClose: () => closeModal(),
        children: _ref3 => {
          let {
            notifyError
          } = _ref3;
          return _jsxs(_Fragment, {
            children: [_jsx(PanelHeader, {
              children: I18n.t('delete_app.header')
            }), _jsx(PanelDescription, {
              children: _jsx(HyperText, {
                content: I18n.t('delete_app.message', {
                  appName: '{appName}'
                }),
                children: _jsx(HyperText, {
                  className: "deleted-app-name",
                  css: {
                    fontWeight: 'bolder'
                  },
                  content: DOMPurify.sanitize(appName)
                }, "appName")
              })
            }), deleteApplication && _jsx(NetworkErrorBoundary, {
              resetKeys: [deleteApplication],
              onError: () => notifyError(I18n.t('error.service.apps')),
              children: _jsx(Suspense, {
                children: _jsx(ResourceSuccess, {
                  resource: deleteApplication,
                  onSuccess: result => closeModal(result)
                })
              })
            }), _jsxs(PanelFooter, {
              children: [_jsx(Button, {
                type: "reset",
                className: "cancel-button",
                autoFocus: true,
                link: true,
                children: I18n.t('buttons.cancel')
              }), _jsx(WarningButton, {
                className: "delete-app-button",
                type: "submit",
                disabled: isPending,
                children: isPending ? I18n.t('delete_app.deleting') : I18n.t('delete_app.action_button')
              })]
            })]
          });
        }
      });
    }
  });
};
export default DeleteAppModal;