import _toString from "lodash-es/toString";
import _compact from "lodash-es/compact";
import _map from "lodash-es/map";
var _this = this;
import join from 'url-join';
const urlJoin = function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return join.apply(_this, _map(_compact(args), _toString));
};
export default urlJoin;