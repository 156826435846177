import _includes from "lodash-es/includes";
import _some from "lodash-es/some";
import * as SentryBrowser from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { mapValuesDeep, scrubSensitiveData } from './lib/utils/utils.js';
import I18n from './i18n.js';
const Sentry = SentryBrowser;
const sentryDsn = () => globalThis.config.sentry_dsn;
const sentryEnabled = () => !!sentryDsn();
const initSentry = () => {
  if (sentryEnabled()) {
    try {
      Sentry.init({
        dsn: sentryDsn(),
        integrations: [new BrowserTracing()],
        // TODO: Uncomment this block if we consider using mixpanel for OIC
        // beforeBreadcrumb: breadcrumb =>
        // skipping calls to Mixpanel from Sentry recording, as this is not our user activity (but a foreign APIs one)
        // _.includes(breadcrumb.data?.url, 'mixpanel.com') ? null : breadcrumb,
        beforeSend: event => sentryEnabled() ? mapValuesDeep(event, (value, key) => _some(['url', 'from', 'to'], target => _includes(key, target)) ? scrubSensitiveData(value) : value) : null
      });
      const info = {
        release: globalThis.config.release,
        version: globalThis.config.version,
        environment: globalThis.config.env,
        current_locale: I18n.locale,
        release_date: globalThis.config.release_date
      };
      Sentry.setTags(info);
      // duplicating some tags as extras for better Sentry UX (to see this data in the page Additional Data section)
      Sentry.setExtras(info);
    } catch (e) {
      // eslint-disable-next-line no-console
      console?.error('Failed to initialize Sentry:', e);
    }
  } else {
    try {
      Sentry.close(0);
    } catch (e) {
      // eslint-disable-next-line no-console
      if (e) console?.error('Failed to close Sentry:', e);
    }
  }
};
export const captureException = function () {
  return sentryEnabled() && Sentry.captureException(...arguments);
};
export default initSentry;