import _keys from "lodash-es/keys";
import _head from "lodash-es/head";
import { colors, Tabs, Tab, useLoading, BarStackHorizontal } from '@lookout/ui';
import React, { useState, useMemo } from 'react';
import { format } from 'd3-format';
import { ThemeProvider } from '@emotion/react';
import { deviceRegistrationsSampleData } from '../charts-test-data.js';
import I18n from '../../../i18n.js';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const androidColor = colors.teal150;
const iosColor = colors.blue100;
const transparentColor = colors.transparent;
const registrationsChartMargins = {
  top: 10,
  left: 65,
  right: 40,
  bottom: 65
};
const RegistrationsChartTooltip = _ref => {
  let {
    data: {
      osVersion,
      count
    }
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      className: "registrations-os-ver",
      children: I18n.t('dashboard.charts.registrations.os_version', {
        version: osVersion
      })
    }), _jsx("div", {
      className: "registrations-count",
      children: I18n.t('dashboard.charts.detections.num_of_devices_with_count', {
        count: I18n.numberToRounded(count, {
          precision: 0
        })
      })
    })]
  });
};
const NoDataTooltip = () => _jsx("div", {
  className: "no-chart-data-tooltip",
  children: I18n.t('dashboard.charts.registrations.no_data')
});
const transformDeviceRegistrationsTotals = deviceRegistrations => [{
  ios: Math.round(deviceRegistrations.ios_total / deviceRegistrations.total_registrations * 100),
  android: Math.round(deviceRegistrations.android_total / deviceRegistrations.total_registrations * 100)
}];

// TODO: to use semver OS version sort https://jira.corp.lookout.com/browse/JS-3231
const osVersionComparator = (_ref2, _ref3) => {
  let {
    count: a
  } = _ref2;
  let {
    count: b
  } = _ref3;
  return a - b;
};
const RegistrationsChart = _ref4 => {
  let {
    deviceRegistrations,
    isPending,
    dateRange,
    titleStyle,
    height,
    tabsTheme = {}
  } = _ref4;
  const [registrationsCategory, setRegistrationsCategory] = useState('total_registrations');
  const {
    elementRef,
    loadingVisible
  } = useLoading(isPending);
  const groupedDeviceRegistrations = useMemo(() => ({
    ...deviceRegistrations.value(),
    ios_versions: deviceRegistrations.value().ios_versions?.sort(osVersionComparator),
    android_versions: deviceRegistrations.value().android_versions?.sort(osVersionComparator)
  }), [deviceRegistrations]);
  const totalRegistrationsChartData = useMemo(() => transformDeviceRegistrationsTotals(groupedDeviceRegistrations), [groupedDeviceRegistrations]);
  const isRegistrationsChartDataEmpty = useMemo(() => !(_head(totalRegistrationsChartData).ios || _head(totalRegistrationsChartData).android), [totalRegistrationsChartData]);
  return _jsxs("figure", {
    ref: elementRef,
    css: {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.white,
      '&.loading.in > :not(figcaption)': {
        opacity: 0.5
      }
    },
    children: [_jsx("figcaption", {
      children: _jsx("h3", {
        css: titleStyle,
        className: "registrations-chart-title",
        children: loadingVisible ? I18n.t('doc_title.loading') : I18n.t('dashboard.charts.registrations.title', {
          dateRange: I18n.t(`dashboard.filters.${dateRange}`)
        })
      })
    }), _jsx(ThemeProvider, {
      theme: tabsTheme,
      children: _jsxs(Tabs, {
        children: [_jsxs(Tab, {
          className: "total-registrations-tab",
          selected: registrationsCategory === 'total_registrations',
          onClick: () => setRegistrationsCategory('total_registrations'),
          children: [_jsx("div", {
            css: {
              color: colors.black300
            },
            children: groupedDeviceRegistrations.total_registrations?.toLocaleString()
          }), _jsx("span", {
            children: I18n.t('os.all')
          })]
        }), _jsxs(Tab, {
          className: "ios-total-tab",
          selected: registrationsCategory === 'ios_total',
          onClick: () => setRegistrationsCategory('ios_total'),
          children: [_jsx("div", {
            css: {
              color: iosColor
            },
            children: groupedDeviceRegistrations.ios_total?.toLocaleString()
          }), _jsx("span", {
            children: I18n.t('os.ios')
          })]
        }), _jsxs(Tab, {
          className: "android-total-tab",
          selected: registrationsCategory === 'android_total',
          onClick: () => setRegistrationsCategory('android_total'),
          children: [_jsx("div", {
            css: {
              color: androidColor
            },
            children: groupedDeviceRegistrations.android_total?.toLocaleString()
          }), _jsx("span", {
            children: I18n.t('os.android')
          })]
        })]
      })
    }), registrationsCategory === 'total_registrations' ? _jsx(BarStackHorizontal, {
      ...(isRegistrationsChartDataEmpty ? {
        data: transformDeviceRegistrationsTotals(deviceRegistrationsSampleData),
        colorsRange: [transparentColor],
        Tooltip: NoDataTooltip
      } : {
        data: totalRegistrationsChartData,
        colorsRange: [iosColor, androidColor]
      }),
      className: "all-os-registrations-chart",
      keys: _keys(_head(totalRegistrationsChartData)),
      height: height,
      barStackText: {
        label: val => val === 0 ? 0 : `${val}%`
      },
      axisBottom: {
        tickFormat: val => val === 0 ? 0 : `${val}%`
      },
      showAxisLeft: false,
      yScaleConfig: {
        padding: 0.7
      },
      legend: {
        labelFormat: val => I18n.t(`os.${val}`)
      }
    }) : _jsx(BarStackHorizontal, {
      ...(registrationsCategory === 'ios_total' && {
        className: 'ios-registrations-chart',
        ...(groupedDeviceRegistrations.ios_total ? {
          data: groupedDeviceRegistrations.ios_versions,
          colorsRange: [iosColor],
          Tooltip: RegistrationsChartTooltip
        } : {
          data: deviceRegistrationsSampleData.ios_versions,
          colorsRange: [transparentColor],
          Tooltip: NoDataTooltip
        })
      }),
      ...(registrationsCategory === 'android_total' && {
        className: 'android-registrations-chart',
        ...(groupedDeviceRegistrations.android_total ? {
          data: groupedDeviceRegistrations.android_versions,
          colorsRange: [androidColor],
          Tooltip: RegistrationsChartTooltip
        } : {
          data: deviceRegistrationsSampleData.android_versions,
          colorsRange: [transparentColor],
          Tooltip: NoDataTooltip
        })
      }),
      keys: ['count'],
      y: _ref5 => {
        let {
          osVersion
        } = _ref5;
        return osVersion;
      },
      axisLeft: {
        label: I18n.t('dashboard.charts.registrations.os_versions')
      },
      axisBottom: {
        label: I18n.t('dashboard.charts.registrations.registrations'),
        tickFormat: format('.2~s')
      },
      height: height,
      margins: registrationsChartMargins,
      barStackText: {
        css: {
          display: 'none'
        }
      },
      css: {
        '.visx-legend': {
          visibility: 'hidden'
        }
      } // making legend hidden to align with the height of total_registrations charts in the same layout
    })]
  });
};
export default RegistrationsChart;