import React from 'react';
import { Layout, Spinner } from '@lookout/ui';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const Fallback = _ref => {
  let {
    className,
    message,
    spinner
  } = _ref;
  return _jsx(Layout, {
    className: className,
    css: {
      minHeight: '33vh',
      alignItems: 'center',
      justifyContent: 'center'
    },
    horizontal: true,
    children: spinner ? _jsx(Spinner, {
      size: 10
    }) : _jsx("p", {
      children: message
    })
  });
};
export default Fallback;