import { colors, Layout } from '@lookout/ui';
import React, { useState } from 'react';
import { AtlasArrowThinDown, MiscPlay } from '@lookout/dingbats';
import OffsiteLink from '../micro/offsite-link.js';
import protectedDeviceIcon from '../../assets/images/protected-device.svg';
import I18n from '../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const docItemStyle = {
  display: 'inline-block',
  borderLeft: `1px solid ${colors.lightBlueGray300}`,
  padding: '0 0 0 15px',
  '> p': {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 16
  },
  '> ul': {
    padding: 0,
    listStyle: 'none',
    '> li': {
      marginBottom: 0
    }
  }
};
const mainIconWidth = 35;
const mainTitleLeftMargin = 15;
const playIconCropFix = -11;
const DocsCollapsible = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const toggleCollapsible = value => {
    setAnimate(true);
    setIsOpen(value);
  };
  return _jsxs(_Fragment, {
    children: [_jsx(Layout, {
      css: {
        alignItems: 'center',
        justifyContent: 'space-between'
      },
      horizontal: true,
      children: _jsxs("h3", {
        css: {
          display: 'flex',
          alignItems: 'center',
          letterSpacing: 0.4,
          color: colors.black300,
          whiteSpace: 'nowrap'
        },
        children: [_jsx("img", {
          src: protectedDeviceIcon,
          css: {
            width: mainIconWidth
          },
          alt: I18n.t('dashboard.docs.icon')
        }), _jsx("span", {
          css: {
            verticalAlign: 'middle',
            marginLeft: mainTitleLeftMargin,
            marginRight: 8
          },
          children: I18n.t('dashboard.docs.title')
        }), _jsx(AtlasArrowThinDown, {
          className: "toggle-docs-collapsible",
          onClick: () => toggleCollapsible(!isOpen),
          css: [{
            height: 22,
            width: 22,
            verticalAlign: 'middle',
            transition: animate ? 'transform .3s ease' : 'none',
            transformOrigin: 'center center',
            color: colors.black100,
            cursor: 'pointer'
          }, isOpen && {
            transform: 'rotate(180deg)'
          }]
        })]
      })
    }), _jsx("div", {
      css: {
        display: isOpen ? 'block' : 'none',
        marginLeft: mainIconWidth + mainTitleLeftMargin
      },
      children: _jsxs("div", {
        css: docItemStyle,
        children: [_jsx("p", {
          children: "Download Lookout SDK"
        }), _jsxs("ul", {
          children: [_jsxs("li", {
            children: [_jsx(MiscPlay, {
              fill: colors.lookout,
              height: 32,
              width: 32,
              css: {
                marginLeft: playIconCropFix,
                verticalAlign: 'middle'
              }
            }), _jsx(OffsiteLink, {
              href: globalThis.config.ios_guide_url,
              css: {
                fontWeight: 500
              },
              target: "_blank",
              className: "download-ios-guide-link",
              children: I18n.t('home.bottom_1.link_ios')
            })]
          }), _jsxs("li", {
            children: [_jsx(MiscPlay, {
              fill: colors.lookout,
              height: 32,
              width: 32,
              css: {
                marginLeft: playIconCropFix,
                verticalAlign: 'middle'
              }
            }), _jsx(OffsiteLink, {
              href: globalThis.config.android_guide_url,
              target: "_blank",
              css: {
                fontWeight: 500
              },
              className: "download-android-guide-link",
              children: I18n.t('home.bottom_1.link_android')
            })]
          })]
        })]
      })
    })]
  });
};
export default DocsCollapsible;