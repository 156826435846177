import React from 'react';
import classes from '../../lib/utils/classes.js';
import I18n from '../../i18n.js';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const EmptyTableRow = _ref => {
  let {
    className,
    cellStyle,
    children = I18n.t('empty.text')
  } = _ref;
  return _jsx("tr", {
    className: classes(className, 'empty'),
    children: _jsx("td", {
      colSpan: 999,
      css: cellStyle,
      children: children
    })
  });
};
export default EmptyTableRow;