import _reduce from "lodash-es/reduce";
import _isArray from "lodash-es/isArray";
import _isFunction from "lodash-es/isFunction";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
export const stringifyQuery = function () {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let replacer = arguments.length > 1 ? arguments[1] : undefined;
  let encodeFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : encodeURIComponent;
  if (!_isFunction(replacer)) {
    replacer = (key, value) => value != null ? _isArray(value) ? value.map(v => ({
      key,
      value: v
    })) : {
      key,
      value
    } : null;
  }
  return Object.keys(params).reduce((pairs, key) => pairs.concat(replacer(key, params[key]) || []), []).map(_ref => {
    let {
      key,
      value
    } = _ref;
    return [key, value].map(encodeFn).join('=');
  }).join('&');
};
export const parseQuery = function () {
  let string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let reviver = arguments.length > 1 ? arguments[1] : undefined;
  if (!_isFunction(reviver)) {
    reviver = (key, value) => ({
      key,
      value
    });
  }
  string = string.slice(1 + string.indexOf('?'));
  return string.split('&').reduce((params, pair) => {
    const parts = pair.split('=').map(decodeURIComponent);
    const {
      key,
      value
    } = reviver(...parts) || {};
    if (value != null) {
      // eslint-disable-next-line no-prototype-builtins
      params[key] = params.hasOwnProperty(key) ? [].concat(params[key], value) : value;
    }
    return params;
  }, {});
};
export const toPlainObject = searchParams => _reduce(Array.from(searchParams.entries()), (result, _ref2) => {
  let [key, value] = _ref2;
  return {
    ...result,
    [key]: value
  };
}, {});