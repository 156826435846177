export const detectionsTestData = [{
  date_range: 'last_30_days',
  category: 'NETWORK',
  sum: 14,
  values: [{
    classification: 'MITM',
    severity: 'HIGH',
    count: 14,
    category: 'NETWORK',
    ios: 1,
    android: 11
  }]
}, {
  date_range: 'last_30_days',
  category: 'DEVICE',
  sum: 4,
  values: [{
    classification: 'ROOT_JAILBREAK',
    severity: 'HIGH',
    count: 2,
    category: 'DEVICE_ROOT_JAILBREAK',
    ios: 2,
    android: 2
  }]
}, {
  date_range: 'last_30_days',
  category: 'APP_THREATS',
  sum: 319,
  values: [{
    classification: 'SURVEILLANCE',
    severity: 'HIGH',
    count: 104,
    category: 'APP_MALWARE',
    ios: 3,
    android: 101
  }, {
    classification: 'ROOT_ENABLER',
    severity: 'MEDIUM',
    count: 104,
    category: 'APP_ROOT_ENABLER',
    ios: 4,
    android: 100
  }, {
    classification: 'UNKNOWN_RISKWARE',
    severity: 'LOW',
    count: 24,
    category: 'APP_RISKWARE',
    ios: 5,
    android: 19
  }, {
    classification: 'ADWARE',
    severity: 'LOW',
    count: 87,
    category: 'APP_ADWARE',
    ios: 6,
    android: 81
  }]
}, {
  date_range: 'last_30_days',
  category: 'ALL',
  sum: 131,
  values: [{
    classification: 'MITM',
    severity: 'HIGH',
    count: 14,
    category: 'NETWORK',
    ios: 7,
    android: 7
  }, {
    classification: 'SURVEILLANCE',
    severity: 'HIGH',
    count: 34,
    category: 'APP_MALWARE',
    ios: 8,
    android: 26
  }, {
    classification: 'ROOT_ENABLER',
    severity: 'MEDIUM',
    count: 18,
    category: 'APP_ROOT_ENABLER',
    ios: 9,
    android: 9
  }, {
    classification: 'UNKNOWN_RISKWARE',
    severity: 'LOW',
    count: 33,
    category: 'APP_RISKWARE',
    ios: 10,
    android: 23
  }, {
    classification: 'ADWARE',
    severity: 'LOW',
    count: 18,
    category: 'APP_ADWARE',
    ios: 11,
    android: 7
  }, {
    classification: 'ROOT_JAILBREAK',
    severity: 'HIGH',
    count: 14,
    category: 'DEVICE_ROOT_JAILBREAK',
    ios: 12,
    android: 2
  }]
}];
export const threatsSampleData = [{
  category: 'Network',
  MEDIUM: 30,
  LOW: 30,
  HIGH: 40
}, {
  category: 'Device',
  MEDIUM: 40,
  LOW: 30,
  HIGH: 30
}, {
  category: 'App',
  MEDIUM: 30,
  LOW: 40,
  HIGH: 30
}];
export const detectionsTestEmptyData = [{
  date_range: 'last_30_days',
  category: 'NETWORK',
  sum: 0,
  values: []
}, {
  date_range: 'last_30_days',
  category: 'DEVICE',
  sum: 0,
  values: []
}, {
  date_range: 'last_30_days',
  category: 'APP_THREATS',
  sum: 0,
  values: []
}, {
  date_range: 'last_30_days',
  category: 'ALL',
  sum: 0,
  values: []
}];
export const deviceRegistrationsTestData = {
  date_range: 'last_30_days',
  total_registrations: 5,
  ios_total: 3,
  android_total: 2,
  ios_versions: [{
    osVersion: '13.0',
    count: 9
  }, {
    osVersion: '11.1',
    count: 4
  }, {
    osVersion: '11.2',
    count: 1
  }],
  android_versions: [{
    osVersion: '1',
    count: 1
  }, {
    osVersion: '2',
    count: 2
  }]
};
export const deviceRegistrationsNoData = {
  date_range: 'last_30_days',
  total_registrations: 0,
  ios_total: 0,
  android_total: 0,
  ios_versions: [],
  android_versions: []
};
export const deviceRegistrationsSampleData = {
  date_range: 'last_30_days',
  total_registrations: 2,
  ios_total: 1,
  android_total: 1,
  ios_versions: [{
    osVersion: '',
    count: 100
  }],
  android_versions: [{
    osVersion: '',
    count: 100
  }]
};
export const deviceDetectionsTestData = {
  date_range: 'last_30_days',
  sum: 63173,
  values: [{
    date: '05-04-2021',
    count: 672
  }, {
    date: '05-05-2021',
    count: 672
  }, {
    date: '05-06-2021',
    count: 672
  }, {
    date: '05-07-2021',
    count: 672
  }, {
    date: '05-08-2021',
    count: 672
  }, {
    date: '05-09-2021',
    count: 672
  }, {
    date: '05-10-2021',
    count: 756
  }, {
    date: '05-11-2021',
    count: 756
  }, {
    date: '05-12-2021',
    count: 756
  }, {
    date: '05-13-2021',
    count: 840
  }, {
    date: '05-14-2021',
    count: 756
  }, {
    date: '05-15-2021',
    count: 672
  }, {
    date: '05-16-2021',
    count: 672
  }, {
    date: '05-17-2021',
    count: 672
  }, {
    date: '05-18-2021',
    count: 1008
  }, {
    date: '05-19-2021',
    count: 672
  }, {
    date: '05-20-2021',
    count: 672
  }, {
    date: '05-21-2021',
    count: 672
  }, {
    date: '05-22-2021',
    count: 672
  }, {
    date: '05-23-2021',
    count: 672
  }, {
    date: '05-24-2021',
    count: 756
  }, {
    date: '05-25-2021',
    count: 677
  }, {
    date: '05-26-2021',
    count: 672
  }, {
    date: '05-27-2021',
    count: 672
  }, {
    date: '05-28-2021',
    count: 672
  }, {
    date: '05-29-2021',
    count: 588
  }, {
    date: '05-30-2021',
    count: 672
  }, {
    date: '05-31-2021',
    count: 672
  }, {
    date: '06-01-2021',
    count: 672
  }, {
    date: '06-02-2021',
    count: 672
  }, {
    date: '06-03-2021',
    count: 672
  }, {
    date: '06-04-2021',
    count: 672
  }, {
    date: '06-05-2021',
    count: 672
  }, {
    date: '06-06-2021',
    count: 672
  }, {
    date: '06-07-2021',
    count: 672
  }, {
    date: '06-08-2021',
    count: 672
  }, {
    date: '06-09-2021',
    count: 672
  }, {
    date: '06-10-2021',
    count: 672
  }, {
    date: '06-11-2021',
    count: 672
  }, {
    date: '06-12-2021',
    count: 672
  }, {
    date: '06-13-2021',
    count: 672
  }, {
    date: '06-14-2021',
    count: 756
  }, {
    date: '06-15-2021',
    count: 672
  }, {
    date: '06-16-2021',
    count: 672
  }, {
    date: '06-17-2021',
    count: 756
  }, {
    date: '06-18-2021',
    count: 756
  }, {
    date: '06-19-2021',
    count: 672
  }, {
    date: '06-20-2021',
    count: 672
  }, {
    date: '06-21-2021',
    count: 756
  }, {
    date: '06-22-2021',
    count: 672
  }, {
    date: '06-23-2021',
    count: 672
  }, {
    date: '06-24-2021',
    count: 672
  }, {
    date: '06-25-2021',
    count: 672
  }, {
    date: '06-26-2021',
    count: 672
  }, {
    date: '06-27-2021',
    count: 672
  }, {
    date: '06-28-2021',
    count: 840
  }, {
    date: '06-29-2021',
    count: 756
  }, {
    date: '06-30-2021',
    count: 924
  }, {
    date: '07-01-2021',
    count: 672
  }, {
    date: '07-02-2021',
    count: 672
  }, {
    date: '07-03-2021',
    count: 672
  }, {
    date: '07-04-2021',
    count: 672
  }, {
    date: '07-05-2021',
    count: 672
  }, {
    date: '07-06-2021',
    count: 672
  }, {
    date: '07-07-2021',
    count: 672
  }, {
    date: '07-08-2021',
    count: 672
  }, {
    date: '07-09-2021',
    count: 672
  }, {
    date: '07-10-2021',
    count: 672
  }, {
    date: '07-11-2021',
    count: 672
  }, {
    date: '07-12-2021',
    count: 420
  }, {
    date: '07-13-2021',
    count: 756
  }, {
    date: '07-14-2021',
    count: 672
  }, {
    date: '07-15-2021',
    count: 672
  }, {
    date: '07-16-2021',
    count: 672
  }, {
    date: '07-17-2021',
    count: 672
  }, {
    date: '07-18-2021',
    count: 672
  }, {
    date: '07-19-2021',
    count: 672
  }, {
    date: '07-20-2021',
    count: 840
  }, {
    date: '07-21-2021',
    count: 840
  }, {
    date: '07-22-2021',
    count: 672
  }, {
    date: '07-23-2021',
    count: 672
  }, {
    date: '07-24-2021',
    count: 672
  }, {
    date: '07-25-2021',
    count: 672
  }, {
    date: '07-26-2021',
    count: 840
  }, {
    date: '07-27-2021',
    count: 672
  }, {
    date: '07-28-2021',
    count: 672
  }, {
    date: '07-29-2021',
    count: 756
  }, {
    date: '07-30-2021',
    count: 756
  }, {
    date: '07-31-2021',
    count: 672
  }, {
    date: '08-01-2021',
    count: 672
  }, {
    date: '08-02-2021',
    count: 504
  }]
};
export const deviceDetectionsTestSmallCountsData = {
  date_range: 'last_7_days',
  sum: 10,
  values: [{
    date: '05-04-2021',
    count: 1
  }, {
    date: '05-05-2021',
    count: 1
  }, {
    date: '05-06-2021',
    count: 1
  }, {
    date: '05-07-2021',
    count: 1
  }, {
    date: '05-08-2021',
    count: 2
  }, {
    date: '05-09-2021',
    count: 3
  }, {
    date: '05-10-2021',
    count: 1
  }]
};
export const deviceDetectionsTestNoData = {
  date_range: 'last_7_days',
  sum: 10,
  values: [{
    date: '05-04-2021',
    count: 0
  }, {
    date: '05-05-2021',
    count: 0
  }, {
    date: '05-06-2021',
    count: 0
  }, {
    date: '05-07-2021',
    count: 0
  }, {
    date: '05-08-2021',
    count: 0
  }, {
    date: '05-09-2021',
    count: 0
  }, {
    date: '05-10-2021',
    count: 0
  }]
};