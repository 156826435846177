import { Global, css } from '@emotion/react';
import { globalStyles } from '@lookout/ui';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const Style = () => _jsxs(_Fragment, {
  children: [_jsx(Global, {
    styles: globalStyles
  }), _jsx(Global, {
    styles: css(import('modern-normalize/modern-normalize.css').toString())
  }), _jsx(Global, {
    styles: css(import('@lookout/fonts/noto-sans/noto-sans-400.css').toString())
  }), _jsx(Global, {
    styles: css(import('@lookout/fonts/noto-sans/noto-sans-700.css').toString())
  })]
});
export default Style;