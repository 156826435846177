import { OSIcon, Table, typography } from '@lookout/ui';
import React from 'react';
import classes from '../../lib/utils/classes.js';
import { belowQuery, screenXsMax } from '../../lib/styles/breakpoints.js';
import { DoubleHeader } from '../micro/double-header.js';
import { mobileResponsiveMargin } from '../../lib/styles/layout-styles.js';
import I18n from '../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AppSummary = _ref => {
  let {
    app
  } = _ref;
  const {
    userName,
    userEmail,
    packageName,
    os,
    comment,
    appName
  } = app.value();
  return _jsxs(_Fragment, {
    children: [_jsx("div", {
      css: {
        margin: 40,
        marginLeft: 0,
        paddingLeft: 10,
        ...mobileResponsiveMargin
      },
      children: _jsx("h1", {
        className: "app-name",
        css: typography.blush,
        children: appName
      })
    }), _jsxs(Table, {
      className: "app-info-table",
      css: [{
        minWidth: 600,
        'thead th': {
          borderBottom: 'none'
        },
        [belowQuery({
          max: screenXsMax
        })]: {
          minWidth: 400,
          thead: {
            tr: {
              th: {
                paddingLeft: 0
              }
            }
          },
          tbody: {
            tr: {
              td: {
                paddingLeft: 0
              }
            }
          }
        }
      }],
      children: [_jsx("thead", {
        children: _jsxs("tr", {
          children: [_jsx("th", {
            children: I18n.t('app_details.package_name')
          }), _jsx("th", {
            children: I18n.t('app_details.developer')
          }), _jsx("th", {
            children: I18n.t('app_details.os')
          }), _jsx("th", {
            children: I18n.t('app_details.comments')
          })]
        })
      }), _jsx("tbody", {
        children: _jsxs("tr", {
          children: [_jsx("td", {
            className: "package-name",
            children: packageName
          }), _jsx("td", {
            children: _jsx(DoubleHeader, {
              className: "developer",
              top: userName,
              bottom: userEmail
            })
          }), _jsx("td", {
            children: _jsx(OSIcon, {
              className: classes('os', os),
              os: os
            })
          }), _jsx("td", {
            className: "comment",
            css: typography.breakText,
            children: comment
          })]
        })
      })]
    })]
  });
};
export default AppSummary;