import _size from "lodash-es/size";
import _every from "lodash-es/every";
import _orderBy from "lodash-es/orderBy";
import _keyBy from "lodash-es/keyBy";
import _groupBy from "lodash-es/groupBy";
import _mapValues from "lodash-es/mapValues";
import _reduce from "lodash-es/reduce";
import "core-js/modules/esnext.iterator.map.js";
import { colors, Tabs, Tab, Table, tableStyles, RiskMarker, typography, TableSortingHeader, sortQuery, sortOrder, useLoading, BarStackHorizontal } from '@lookout/ui';
import React, { useState, useMemo } from 'react';
import { ThemeProvider } from '@emotion/react';
import { threatsSampleData } from '../charts-test-data.js';
import { tableLayoutStyles } from '../../../lib/styles/layout-styles.js';
import EmptyTableRow from '../../micro/empty-table-row.js';
import I18n from '../../../i18n.js';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SEVERITIES = ['LOW', 'MEDIUM', 'HIGH'];
const reduceThreatCounts = _ref => {
  let {
    threatsCategoryMap,
    category
  } = _ref;
  return {
    category: I18n.t(`dashboard.charts.threats.chart.${category.toLowerCase()}`),
    ..._reduce(SEVERITIES, (result, severity) => ({
      ...result,
      [severity]: 0
    }), {}),
    ..._mapValues(_groupBy(threatsCategoryMap[category].values, 'severity'), threats => _reduce(threats, (total, _ref2) => {
      let {
        count
      } = _ref2;
      return total + count;
    }, 0))
  };
};
const countsCellStyle = [typography.monospace, {
  textAlign: 'right'
}];
const threatsTableStyle = {
  'thead tr th': {
    fontWeight: 700,
    color: colors.blueGray200
  },
  tbody: {
    fontSize: 13,
    color: colors.black300
  }
};
const NoDataTooltip = () => _jsx("div", {
  className: "no-chart-data-tooltip",
  children: I18n.t('dashboard.charts.registrations.no_data')
});
const threatOppositeCategoryClassMap = {
  NETWORK: '.data-device rect, .data-app rect',
  DEVICE: '.data-network rect, .data-app rect',
  APP_THREATS: '.data-device rect, .data-network rect'
};
const DashboardThreats = _ref3 => {
  let {
    detections,
    isPending,
    dateRange,
    titleStyle,
    tabsTheme = {}
  } = _ref3;
  const [threatCategory, setThreatCategory] = useState('ALL');
  const [threatSort, setThreatSort] = useState({
    sort_by: 'count',
    order: 'desc'
  });
  const {
    elementRef,
    loadingVisible
  } = useLoading(isPending);
  const threatsCategoryMap = useMemo(() => _keyBy(detections.value(), 'category'), [detections]);
  const threats = useMemo(() => _orderBy(threatsCategoryMap[threatCategory].values, threatSort.sort_by, threatSort.order), [threatsCategoryMap, threatCategory, threatSort]);
  const threatsChartData = useMemo(() => [reduceThreatCounts({
    threatsCategoryMap,
    category: 'NETWORK'
  }), reduceThreatCounts({
    threatsCategoryMap,
    category: 'DEVICE'
  }), reduceThreatCounts({
    threatsCategoryMap,
    category: 'APP_THREATS'
  })], [threatsCategoryMap]);
  const customStyle = useMemo(() => threatCategory !== 'ALL' ? {
    [threatOppositeCategoryClassMap[threatCategory]]: {
      fillOpacity: '25%'
    }
  } : null, [threatCategory]);
  const isEmptyData = useMemo(() => _every(threatsChartData, item => !item.MEDIUM && !item.LOW && !item.HIGH), [threatsChartData]);
  return _jsxs("figure", {
    ref: elementRef,
    css: {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.white,
      '&.loading.in > :not(figcaption)': {
        opacity: 0.5
      }
    },
    children: [_jsx("figcaption", {
      children: _jsx("h3", {
        className: "dashboard-threats-title",
        css: titleStyle,
        children: loadingVisible ? I18n.t('doc_title.loading') : I18n.t('dashboard.charts.threats.title', {
          dateRange: I18n.t(`dashboard.filters.${dateRange}`)
        })
      })
    }), _jsx("div", {
      className: "threats-chart-container",
      css: {
        paddingTop: 20
      },
      children: _jsx(BarStackHorizontal, {
        className: "threats-chart",
        keys: SEVERITIES,
        y: _ref4 => {
          let {
            category
          } = _ref4;
          return category;
        },
        axisBottom: {
          tickFormat: val => I18n.numberToRounded(val, {
            precision: 0
          })
        },
        showLegend: false,
        height: 140,
        margins: {
          top: 0,
          left: 80,
          bottom: 50
        },
        yScaleConfig: {
          padding: 0.5
        },
        barStackText: {
          css: {
            display: 'none'
          }
        },
        css: customStyle,
        ...(isEmptyData ? {
          data: threatsSampleData,
          colorsRange: [colors.transparent],
          Tooltip: NoDataTooltip
        } : {
          data: threatsChartData,
          colorsRange: [colors.lowRisk, colors.medRisk, colors.highRisk]
        })
      })
    }), _jsx(ThemeProvider, {
      theme: tabsTheme,
      children: _jsxs(Tabs, {
        children: [_jsxs(Tab, {
          className: "total-threats-tab",
          selected: threatCategory === 'ALL',
          onClick: () => setThreatCategory('ALL'),
          children: [_jsx("div", {
            css: {
              color: colors.black300
            },
            children: I18n.numberToRounded(threatsCategoryMap.ALL.sum, {
              precision: 0
            })
          }), _jsx("span", {
            children: I18n.t('dashboard.charts.threats.category.all')
          })]
        }), _jsxs(Tab, {
          className: "app-threat-tab",
          selected: threatCategory === 'APP_THREATS',
          onClick: () => setThreatCategory('APP_THREATS'),
          children: [_jsx("div", {
            css: {
              color: colors.black300
            },
            children: I18n.numberToRounded(threatsCategoryMap.APP_THREATS.sum, {
              precision: 0
            })
          }), _jsx("span", {
            children: I18n.t('dashboard.charts.threats.category.app_threats')
          })]
        }), _jsxs(Tab, {
          className: "device-threat-tab",
          selected: threatCategory === 'DEVICE',
          onClick: () => setThreatCategory('DEVICE'),
          children: [_jsx("div", {
            css: {
              color: colors.black300
            },
            children: I18n.numberToRounded(threatsCategoryMap.DEVICE.sum, {
              precision: 0
            })
          }), _jsx("span", {
            children: I18n.t('dashboard.charts.threats.category.device')
          })]
        }), _jsxs(Tab, {
          className: "network-threat-tab",
          selected: threatCategory === 'NETWORK',
          onClick: () => setThreatCategory('NETWORK'),
          children: [_jsx("div", {
            css: {
              color: colors.black300
            },
            children: I18n.numberToRounded(threatsCategoryMap.NETWORK.sum, {
              precision: 0
            })
          }), _jsx("span", {
            children: I18n.t('dashboard.charts.threats.category.network')
          })]
        })]
      })
    }), _jsxs(Table, {
      className: "threats-table",
      css: [tableStyles.striped, tableLayoutStyles.fullWidth, threatsTableStyle],
      children: [_jsx("thead", {
        children: _jsxs("tr", {
          children: [_jsx(TableSortingHeader, {
            className: "threat-breakdown-header",
            title: I18n.t('dashboard.charts.threats.threat_breakdown_header'),
            sortOrder: sortOrder({
              sort_by: 'classification',
              query: threatSort
            }),
            onClick: () => setThreatSort(sortQuery({
              sort_by: 'classification',
              query: threatSort
            }))
          }), _jsx(TableSortingHeader, {
            className: "classification-header",
            title: I18n.t('dashboard.charts.threats.classification_header'),
            sortOrder: sortOrder({
              sort_by: 'category',
              query: threatSort
            }),
            onClick: () => setThreatSort(sortQuery({
              sort_by: 'category',
              query: threatSort
            }))
          }), _jsx(TableSortingHeader, {
            className: "ios-detected-header",
            title: I18n.t('dashboard.charts.threats.ios_detected_header'),
            sortOrder: sortOrder({
              sort_by: 'ios',
              query: threatSort
            }),
            css: {
              textAlign: 'right !important'
            },
            onClick: () => setThreatSort(sortQuery({
              sort_by: 'ios',
              query: threatSort
            }))
          }), _jsx(TableSortingHeader, {
            className: "android-detected-header",
            title: I18n.t('dashboard.charts.threats.android_detected_header'),
            sortOrder: sortOrder({
              sort_by: 'android',
              query: threatSort
            }),
            css: {
              textAlign: 'right !important'
            },
            onClick: () => setThreatSort(sortQuery({
              sort_by: 'android',
              query: threatSort
            }))
          }), _jsx(TableSortingHeader, {
            className: "all-os-header",
            title: I18n.t('dashboard.charts.threats.all_os_header'),
            sortOrder: sortOrder({
              sort_by: 'count',
              query: threatSort
            }),
            css: {
              textAlign: 'right !important'
            },
            onClick: () => setThreatSort(sortQuery({
              sort_by: 'count',
              query: threatSort
            }))
          })]
        })
      }), _jsx("tbody", {
        children: _size(threats) > 0 ? threats.map(threat => _jsxs("tr", {
          children: [_jsxs("td", {
            className: "threat-breakdown-cell",
            css: typography.noWrap,
            children: [_jsx(RiskMarker, {
              riskLevel: threat.severity.toLowerCase()
            }), I18n.t(`dashboard.charts.threats.threat_breakdown.${threat.classification}`)]
          }), _jsx("td", {
            className: "threat-classification-cell",
            children: I18n.t(`dashboard.charts.threats.classification.${threat.category}`)
          }), _jsx("td", {
            className: "threat-ios-count",
            css: countsCellStyle,
            children: I18n.numberToRounded(threat.ios, {
              precision: 0
            })
          }), _jsx("td", {
            className: "threat-android-count",
            css: countsCellStyle,
            children: I18n.numberToRounded(threat.android, {
              precision: 0
            })
          }), _jsx("td", {
            className: "threat-total-count",
            css: countsCellStyle,
            children: I18n.numberToRounded(threat.count, {
              precision: 0
            })
          })]
        }, JSON.stringify(threat))) : _jsx(EmptyTableRow, {
          children: I18n.t('dashboard.charts.threats.empty')
        })
      })]
    })]
  });
};
export default DashboardThreats;