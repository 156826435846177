import { useEffect } from 'react';
export const ResourceSuccess = _ref => {
  let {
    resource,
    onSuccess
  } = _ref;
  const response = resource.value();
  useEffect(() => {
    onSuccess(response);
    resource.dispose();
  }, [onSuccess, resource, response]);
  return null;
};