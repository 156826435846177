export const screenXsMin = 480;
export const screenSmMin = 768;
export const screenMdMin = 992;
export const screenLgMin = 1200;
export const screenXlMin = 1440;
export const screenXxsMax = screenXsMin - 1;
export const screenXsMax = screenSmMin - 1;
export const screenSmMax = screenMdMin - 1;
export const screenMdMax = screenLgMin - 1;
export const screenLgMax = screenXlMin - 1;
export const belowQuery = _ref => {
  let {
    max
  } = _ref;
  return `@media (max-width: ${max}px)`;
};
export const withinQuery = _ref2 => {
  let {
    min,
    max
  } = _ref2;
  return `@media (min-width: ${min}px) and (max-width: ${max}px)`;
};
export const aboveQuery = _ref3 => {
  let {
    min
  } = _ref3;
  return `@media (min-width: ${min}px)`;
};
export const maxima = {
  screenXxsMax,
  screenXsMax,
  screenSmMax,
  screenMdMax,
  screenLgMax
};
export const getViewportWidth = () => Math.max(globalThis.document.documentElement.clientWidth || 0, globalThis.window.innerWidth || 0);