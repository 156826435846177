import _map from "lodash-es/map";
import _isArray from "lodash-es/isArray";
import _isPlainObject from "lodash-es/isPlainObject";
import _mapValues from "lodash-es/mapValues";
import _replace from "lodash-es/replace";
import _reduce from "lodash-es/reduce";
import _isString from "lodash-es/isString";
export const guidPattern = '[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}';
const idRegExp = /[\d]+/g;
const guidRegExp = new RegExp(guidPattern, 'g');
export const scrubSensitiveData = str => _isString(str) ? _reduce([guidRegExp, idRegExp], (result, re) => _replace(result, re, '***'), str) : str;
export const mapValuesDeep = (obj, fn) => _mapValues(obj, (val, key) => _isPlainObject(val) ? mapValuesDeep(val, fn) : _isArray(val) ? _map(val, v => _isPlainObject(v) ? mapValuesDeep(v, fn) : v) : fn(val, key, obj));